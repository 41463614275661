import { pricearr } from './components/PriceItem/ArrayPrace';
import PriceItem from './components/PriceItem/PriceItem';
import { useEffect } from 'react';
import { MetaData, Title } from '../../components/';
import { BtnArrow } from '../../components';
import { useAppDispatch } from '../../store';
import { changeModal } from '../../store/modalSlice/modalSlice';

import './style/Price.scss';

export const Price = (): JSX.Element => {
  const addClass = (e: React.MouseEvent<HTMLDivElement>) => {
    const el = (e.target as HTMLElement)?.closest('.priceList__header');
    el?.nextElementSibling?.classList.toggle('add');
    el?.classList.toggle('add');
  };
  const dispatch = useAppDispatch();

  function open() {
    dispatch(changeModal({ isActive: true, component: 'discont', subCom: '' }));
  }

  return (
    <MetaData
      title={`FightGym our price`}
      disc={`FightGym our price. First visit twenty five percent discount`}
    >
      <section className='priceList done indent_last'>
        <div className='indentstart'></div>
        <Title name='Membership' />
        <div className='container'>
          <div className='priceList__row'>
            <div className='priceList__items'>
              {pricearr.map((item) => (
                <div key={item.id} className='priceList__item'>
                  <PriceItem item={item} onClick={(e) => addClass(e)} />
                </div>
              ))}
            </div>
          </div>

          <div className='canfree'>
            <div className='canfree__body'>
              <div className='canfree__text'>
                <p>Or you can start for free</p>
              </div>
              <div className='canfree__btn'>
                <BtnArrow onClick={open}>Enroll</BtnArrow>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Plans /> */}
    </MetaData>
  );
};
