import React, { createContext, useState } from 'react';

import Portal, { PortalT } from './Portal';

import { $ } from 'components/index';

export type StatePortalT = {
  id: number;
  portal: JSX.Element;
};

type ProviderOrtalT = {
  toggleAdd: (arg: Omit<PortalT, 'id'> & { time: number }) => void;
  closePortal: (id: number) => void;
};

type PortalConTConfig = {
  children: React.ReactNode;
};

export const ContextPortal = createContext<ProviderOrtalT>(null!);

const PortalCont: React.FC<PortalConTConfig> = ({ children }): JSX.Element => {
  const [tosts, setTosts] = useState<StatePortalT[]>([]);

  function toggleAdd({
    en,
    ru = '',
    err,
    time,
  }: Omit<PortalT, 'id'> & { time: number }) {
    const id = +(Math.random() * 100).toFixed(0);
    setTosts((item) => [
      ...item,
      {
        portal: (
          <Portal
            key={id}
            en={en}
            ru={ru}
            id={id}
            err={err}
            first={item.length < 1 ? 'first' : ''}
          />
        ),
        id,
      },
    ]);
    setTimeout(() => {
      close(id);
    }, time);
  }

  function closePortal(id: number) {
    close(id);
  }

  function close(id: number) {
    $('.portal' + id).remove('show');
    setTimeout(
      () => setTosts((item) => item.filter((el) => el.id !== id)),
      1000
    );
  }

  return (
    <ContextPortal.Provider value={{ toggleAdd, closePortal }}>
      <>
        <div className='portal__container'>
          {tosts.map((item, index) => item.portal)}
        </div>
        {children}
      </>
    </ContextPortal.Provider>
  );
};

export default PortalCont;
