import { $ } from 'components/index';

export function debounce(f, time: number) {
  let lock = false;
  const thisFun = f as typeof Function;

  return function (...arg: any) {
    if (lock) {
      return;
    }

    thisFun.apply(thisFun, arg);
    lock = true;
    setTimeout(() => {
      lock = false;
    }, time);
  };
}

export function haveScrolled(f) {
  const thisFun = f as typeof Function;
  let scrolled = 0;

  return function (...arg: any) {
    const scrollTop = window.pageYOffset;
    const arg2 = arg.concat([scrolled, scrollTop]);
    thisFun.apply(thisFun, arg2);
    scrolled = scrollTop;
  };
}

export function handlerSideBar(e: Event, scrolled: number, scrollTop: number) {
  const scroll = window.pageYOffset + document.documentElement.clientHeight;
  const path = location.pathname;

  if (scrollTop > 100) {
    $('.header, .phone').add('show');
  } else {
    $('.header, .phone').remove('show');
  }

  if (e.type !== 'resize') {
    if (scrollTop > 200 && scrollTop >= scrolled) {
      $('.header').add('deactive');
    } else {
      $('.header').remove('deactive');
    }
  }

  if (scroll >= window.innerHeight / 2 - 300) {
    $('.bannerloc__container').add('deactive');
  } else {
    $('.bannerloc__container').remove('deactive');
  }
}

export function onScroll(scrolled: number) {
  const scroll = window.pageYOffset + document.documentElement.clientHeight;

  const elements = Array.from(document.querySelectorAll('.scroll'));

  for (const item of elements) {
    if ((item as HTMLElement)?.dataset.block) {
      if (scroll > item.getBoundingClientRect().top + window.pageYOffset) {
        item.classList.add('show');
      }
    } else if ((item as HTMLElement)?.dataset.gym) {
      if (scroll > item.getBoundingClientRect().top + window.pageYOffset) {
        item?.closest('.elem')?.classList.add('show');
      }
    } else if ((item as HTMLElement)?.dataset.footer) {
      // * goroof ========================================================

      const arrowgo = document.querySelector('.goroof') as HTMLElement;
      const root = document.querySelector('#root') as HTMLElement;

      if (
        scroll + 10 >=
        root.getBoundingClientRect().bottom + window.pageYOffset
      ) {
        arrowgo?.classList.add('show');
      } else {
        arrowgo?.classList.remove('show');
      }
      const arrowgotwo = document.querySelector('.footer') as HTMLElement;
      if (
        scroll >
        item.getBoundingClientRect().bottom + window.pageYOffset + 20
      ) {
        arrowgotwo?.classList.add('show');
      } else {
        arrowgotwo?.classList.remove('show');
      }
      // * goroof - end =================================================
    }
  }
}
