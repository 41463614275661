import React from 'react';
import MyImage from '../../../../../img/header/logo.jpg';

export class Logo extends React.Component {
  render() {
    return (
      <div className='header__logo'>
        <div className='header__logoImg'>
          <img src={MyImage} alt='' />
        </div>
        <div translate='no' className='header__logoText'>
          fight
          <br /> gym
        </div>
      </div>
    );
  }
}
