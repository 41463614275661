import { MyJQ } from './MyJQ';

export class ClassJQ extends MyJQ {
  constructor(arg: string | HTMLElement | (string | HTMLElement)[]) {
    super(arg);
  }

  add(className: string) {
    if (this.el.length > 0)
      this.el.map((item) => {
        item.classList.add(className);
      });
  }

  remove(className: string) {
    if (this.el.length > 0)
      this.el.map((item) => {
        item.classList.remove(className);
      });
  }

  toggle(className: string) {
    if (this.el.length > 0)
      this.el.map((item) => {
        item.classList.toggle(className);
      });
  }

  has(className: string) {
    if (this.el.length > 1) {
      const arr = this.el.filter((item) => {
        return item.classList.contains(className);
      });
      if (arr.length > 0) {
        return true;
      } else {
        return false;
      }
    } else if (this.el.length == 1) {
      return this.el[0].classList.contains(className);
    } else {
      return false;
    }
  }
}
