import BodyLockorUn from './BodyLockorUn';
import { $ } from '../components/index';
import { changeMargin } from '../components/index';

export function addClassBurger(e: React.MouseEvent | Event) {
  if (window.innerWidth < 1005 && e.type == 'click') {
    $('.header__menu, .header__burger').toggle('show');
    if (!$('.header__burger').has('show')) {
      $('.header__burger').add('resive');
    }
    BodyLockorUn.bodyLock();
    if (!$('.header__menu').has('show')) {
      BodyLockorUn.bodyUnLock();
    }
  } else {
    $('.header__menu, .header__burger').remove('show');
    BodyLockorUn.bodyUnLock();
  }
}

export function showMenu() {
  const link = document.querySelector('.header__link') as HTMLElement;
  const elem = document.querySelector('.blockicons__elements ') as HTMLElement;

  const links = document.querySelector('.header__links') as HTMLElement;
  const items = document.querySelector('.blockicons__items ') as HTMLElement;

  const profily = document.querySelector('.menu__profily ') as HTMLElement;
  const menuLink = document.querySelector('.menu__link') as HTMLElement;

  const block = document.querySelector('.sort__blockone') as HTMLElement;
  const where = document.querySelector('.shop__body') as HTMLElement;
  const whence = document.querySelector('.sort__wrap ') as HTMLElement;

  const blockUser = document.querySelector(
    '.useraside__setting'
  ) as HTMLElement;
  const whereUser = document.querySelector(
    '.shop__menu.usermenu .shop__body'
  ) as HTMLElement;
  const whenceUser = document.querySelector(
    '.useraside__blockfirst '
  ) as HTMLElement;

  if (window.innerWidth < 1005) {
    profily.appendChild(elem);
    menuLink.appendChild(link);
  } else {
    items.appendChild(elem);
    links.appendChild(link);
  }

  if (blockUser) {
    if (window.innerWidth < 1100) {
      whereUser.appendChild(blockUser);
    } else {
      whenceUser.appendChild(blockUser);
      $('.shop__menu').remove('show');
    }
  }

  if (block) {
    if (window.innerWidth < 1100) {
      where.appendChild(block);
    } else {
      whence.appendChild(block);
      $('.shop__menu').remove('show');
    }
  }
}

let width = document.documentElement.clientWidth;

const handler = (e) => {
  if (width != document.documentElement.clientWidth) {
    width = document.documentElement.clientWidth;
    showMenu();
    addClassBurger(e);
    if (!('documentMode' in window.document)) changeMargin();
  }
};

export function addEventScroll() {
  window.addEventListener('resize', handler);
}
export function removeEventScroll() {
  window.removeEventListener('resize', handler);
}

export function addClassMenu(e) {
  const traget = e.target as HTMLElement;

  if ($) {
    if (traget.closest('.sort__filterblock')) {
      BodyLockorUn.bodyLock();
      $('.shop__menu.shop').add('show');
    } else if (
      traget.closest('.shop__menu.shop') &&
      !traget.closest('.shop__container')
    ) {
      BodyLockorUn.bodyUnLock();
      $('.shop__menu.shop').remove('show');
    }

    if (traget.closest('.useraside__blocksecond')) {
      BodyLockorUn.bodyLock();
      $('.shop__menu.usermenu').add('show');
    } else if (
      traget.closest('.shop__menu.usermenu') &&
      !traget.closest('.shop__container')
    ) {
      BodyLockorUn.bodyUnLock();
      $('.shop__menu.usermenu').remove('show');
    }
  }
}
