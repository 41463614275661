import { $ } from 'components/index';
import { useEffect, createRef } from 'react';
import { ImgConf } from './interface.conf';
import './style.scss';

export const ImgPreload: React.FC<ImgConf> = ({
  webp,
  jpg,
  name,
  rmClass = '',
  style,
  load = true,
}): JSX.Element => {
  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    const newImg = new Image();
    newImg.srcset = webp;
    newImg.alt = name;
    newImg.onload = new (function () {
      $([rmClass, '.imgpreload, .sppiner']).remove('load');

      if (ref.current != null && !ref.current.querySelector('img'))
        if (newImg.width > 0 && newImg.height > 0) {
          $(newImg).append(ref.current);
        } else {
          $(ref.current).style('backgroundImage', `url(${jpg})`);
        }
    })();

    //  newImg.onerror = new (function () {
    //    ref.current != null &&
    //      !ref.current.querySelector('img') &&
    //      $(ref.current).style('backgroundImage', `url(${jpg})`);
    //  })();
  }, []);

  return (
    <div ref={ref} style={style} className='imgpreload load'>
      <span hidden={load} className='sppiner load' />
    </div>
  );
};
