import { $ } from 'components/index';
import './ImgLoading.scss';

export const ImgLoading: React.FC<ImgT> = ({
  name = '',
  img,
  parents = '',
  children,
}) => {
  return (
    <div className={`imgloading load ${name}`}>
      <div className='imgloading__loading'></div>
      <img src={img} onLoad={(e) => handleImageLoaded(e, img, name, parents)} />
      {children}
    </div>
  );
};

const handleImageLoaded = (
  e: React.SyntheticEvent<HTMLDivElement>,
  img: string,
  elem: string,
  parents: string
) => {
  const target = e.target as HTMLElement;
  $(target.closest(`.${elem}`) as HTMLElement).style(
    'backgroundImage',
    `url(${img})`
  );
  $(target.closest(`.imgloading`) as HTMLElement).remove('load');
  if (parents !== '') {
    $(parents).remove('load');
  }
  target.remove();
};

type ImgT = {
  name?: string;
  parents?: string;
  img: string;
  children?: JSX.Element;
};
