export const valid = (item: string, name?: string) => {
  const config: any = {};
  if (item == 'email') {
    config.value = /[a-z0-9%^$#_]*@[a-z\-0-9]*\.[a-z]{2,4}/gi;
    config.message = 'invalid email address';
  }
  if (item == 'tel') {
    config.value = /^\d{9,10}$/gi;
    config.message = 'Invalid number address';
  }
  if (item == 'password') {
    config.value =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/gm;
    config.message =
      'At least eight characters, at least one uppercase letter, one number and one special character';
  }
  if (name == 'code') {
    config.value = /abcAD55/gi;
    config.message = 'Invalid code';
  }
  return config;
};
