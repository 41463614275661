import { $ } from 'components';
import { BgConf } from './interface.conf';

export function bgWebp({ webp, jpg, rmClass = '', where }: BgConf) {
  const newImg = new Image();
  newImg.srcset = webp;
  newImg.onload = new (function () {
    if (newImg.width > 0 && newImg.height > 0) {
      $(where).style('backgroundImage', `url(${newImg.srcset})`);
    } else {
      $(where).style('backgroundImage', `url(${jpg})`);
    }
    $(rmClass).remove('load');
  })();
  newImg.onerror = new (function () {
    $(where).style('backgroundImage', `url(${jpg})`);
  })();
}
