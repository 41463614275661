import React from 'react';
import { ReactComponent as Inst } from '../../../../../img/icons/inst.svg';
import { ReactComponent as Twi } from '../../../../../img/icons/twitter.svg';
import { ReactComponent as Face } from '../../../../../img/icons/face.svg';
import './style/HeaderMenu.scss';

export class HeaderMenu extends React.Component {
  render() {
    return (
      <div className='header__menu menu'>
        <div className='menu__body'>
          <div className='container menu__profily'></div>
          <div className='menu__link'></div>
          <div className='menu__footer'>
            <div className='menu__icon'>
              <Inst />
            </div>
            <div className='menu__icon'>
              <Twi />
            </div>
            <div className='menu__icon'>
              <Face />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
