import { CSSProperties } from 'react';
import actionJQ from './actionJQ';

export class MyJQ {
  el: HTMLElement[];
  elem: HTMLElement;
  length: number;

  constructor(arg: string | HTMLElement | (string | HTMLElement)[]) {
    this.el = this.toArray(arg);
    this.elem = this.el[0];
    this.toArray = this.toArray.bind(this);
    this.length = this.el.length;
  }

  private toArray(arg: string | HTMLElement | (string | HTMLElement)[]) {
    let target: HTMLElement[] = [];

    if (typeof arg == 'string' && arg != '') {
      target = this.sliceString(arg);
    } else if (Array.isArray(arg)) {
      arg.map((item) => {
        if (typeof item == 'string' && item != '') {
          target.push(...this.sliceString(item));
        } else if (item != '' && item?.nodeType == 1) {
          target.push(item);
        }
      });
    } else if (arg != '' && arg?.nodeType == 1) {
      target.push(arg);
    }

    return target;
  }

  private sliceString(arr: string) {
    const elements: HTMLElement[] = [];
    arr.split(',').map((item) => {
      elements.push(
        ...(Array.from(
          document.querySelectorAll(item.replace(/^\s*(.+)\s*$/, '$1'))
        ) as HTMLElement[])
      );
    });

    return elements;
  }

  closest(className: string) {
    const elements: HTMLElement[] = [];
    this.el.map((item) => {
      if (item.closest(className))
        elements.push(item.closest(className) as HTMLElement);
    });

    return actionJQ(elements);
  }

  children(className: string) {
    const elements: HTMLElement[] = [];
    this.el.map((item) => {
      const child = item.querySelector(className);
      if (child) elements.push(child as HTMLElement);
    });

    return actionJQ(elements);
  }

  style(key: keyof CSSProperties, value: string | number) {
    let valueNew = value;

    if (
      /width|height|left|right|top|bottom|max(Height|Width)|min(Height|Width)/.test(
        key.toString()
      )
    ) {
      valueNew =
        !/%|px|vh|vw|em|rem|svh|lvh|dvh|svw|lvw|dvw/.test(value.toString()) &&
        +value
          ? value + 'px'
          : value;
    }

    this.el.map((item: any) => {
      item.style[key] = valueNew;
    });
  }

  exceptTarget(className: string) {
    const elements: HTMLElement[] = Array.from(
      document.querySelectorAll(className)
    );
    const newArray: HTMLElement[] = [];
    if (elements.length)
      for (const item of elements) {
        if (item !== this.el[0]) {
          newArray.push(item);
        }
      }
    return actionJQ(newArray);
  }

  focus() {
    this.el[0].focus();
  }

  append(className: string | HTMLElement) {
    const element =
      typeof className == 'string'
        ? document.querySelector(className)
        : className;
    this.el.map((item) => {
      if (element != null) {
        element.append(item);
      }
    });
  }
  prepend(className: string) {
    this.el.map((item) => {
      item.prepend(className);
    });
  }
}
