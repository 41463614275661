class BodyLockorUn {
  body: HTMLBodyElement;
  unlock: boolean;
  timeout: number;

  constructor() {
    this.body = document.querySelector('body') as HTMLBodyElement;
    this.unlock = true;
    this.timeout = 300;
  }

  async bodyLock() {
    const lockPaddingValue =
      window.innerWidth -
      (document.querySelector('.wrapper') as HTMLDivElement).offsetWidth +
      'px';

    Array.from(document.querySelectorAll('.lock-padding')).map((item) => {
      (item as HTMLElement).style.paddingRight = lockPaddingValue;
    });

    this.body.style.paddingRight = lockPaddingValue;
    this.body.classList.add('lock');
    this.unlock = false;
    setTimeout(() => {
      this.unlock = true;
    }, this.timeout);
  }

  bodyUnLock() {
    setTimeout(() => {
      Array.from(document.querySelectorAll('.lock-padding')).map((item) => {
        (item as HTMLElement).style.paddingRight = '0px';
      });

      this.body.style.paddingRight = '0px';
      this.body.classList.remove('lock');
    }, this.timeout);
    this.unlock = false;
    setTimeout(() => {
      this.unlock = true;
    }, this.timeout);
  }
}

export default new BodyLockorUn();
