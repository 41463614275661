import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { $, Button, Input } from '../../../components';
import {
  closeModel,
  closeThenModel,
} from '../../../store/modalSlice/modalSlice';
import { clearCodeUser, useAppDispatch } from '../../../store';

import './style/Discount.scss';

export default function Discount({ setActive }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ mode: 'onBlur' });
  const dispatch = useAppDispatch();

  const submit: SubmitHandler<FieldValues> = () => {
    alert('Promo Code: abcAD55');
    setActive(false);
  };

  function close() {
    dispatch(closeModel());
    dispatch(clearCodeUser());
    $('.modal').elem.ontransitionend = function () {
      dispatch(closeThenModel());
      $('.modal').elem.ontransitionend = function () {
        return false;
      };
    };
  }

  return (
    <div className='discount'>
      <div className='discount__body'>
        <div className='discount__title'>
          <h1>10% on first order</h1>
        </div>
        <div className='discount__text'>
          <p>A code will be sent to your mail</p>
        </div>
        <form onSubmit={handleSubmit(submit)} className='discount__input'>
          <Input
            req={true}
            register={register}
            type='text'
            name='email'
            textFiled='Email'
            errors={errors}
            setValue={setValue}
          />
          <div className='discount__btn'>
            <Button name='local'>
              <p className='mini'>get a discount</p>
            </Button>
          </div>
        </form>
        <div className='discount__no'>
          <p onClick={close} className='mini'>
            No, Thanks!
          </p>
        </div>
      </div>
      <div className='discount__img'></div>
    </div>
  );
}
