import axios, { AxiosError } from 'axios';
import {
  ResponseUserT,
  UserLoginSliceT,
  UserRegistrationT,
} from '../store.conf';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserStateT } from '../store.conf';
import { $authHost, $host } from '../http';

function setDone(state, action: PayloadAction<ResponseUserT>) {
  if (action.payload?.message === 'Ok') {
    state.user = action.payload.user;
    state.isAuth = true;
    state.code = '';
  }
}

function preLoading(state, action: PayloadAction<any>) {
  if (action.payload.data?.message == 'Ok') {
    state.predUser = action.payload.data;
    state.page = 'last';
  } else {
    console.log(action.payload?.message);
  }
}
function loading(state) {
  state.isLoading = !state.isLoading;
}

const initialState: UserStateT = {
  user: {},
  predUser: {} as ResponseUserT,
  isAuth: false,
  isLoading: false,
  code: '',
  page: 'first',
  error: {},
};

type ReducersT = {
  [key: string]: string;
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    errorChange(state) {
      state.error = {};
    },
    exitUser(state) {
      localStorage.setItem('jwtgym', '{}');
      state.isAuth = false;
      state.user = {};
    },
    clearCodeUser(state) {
      state.code = '';
      state.isLoading = false;
    },
    changePageUser(state, action: PayloadAction<ReducersT>) {
      state.page = action.payload.to;
      state.isLoading = false;
    },
    codeLoginUser(state) {
      state.user = state.predUser.user;
      state.isAuth = true;
      state.code = '';
      state.page = 'first';
      localStorage.setItem('jwtgym', JSON.stringify(state.predUser.token));
    },
  },

  extraReducers: function (builder) {
    builder
      .addCase(checkUserHTTP.fulfilled, setDone)
      .addCase(loginUserPreHTTP.pending, loading)
      .addCase(updateUserHTTP.pending, loading)
      .addCase(updatepassUserHTTP.pending, loading)
      .addCase(UpUserPreHTTP.pending, loading)
      .addCase(coderUserHTTP.pending, loading)
      .addCase(updateUserHTTP.fulfilled, setDone)
      .addCase(updatepassUserHTTP.fulfilled, function (state, action) {
        state.isLoading = false;
        if (action.payload.data.message == 'Ok') {
          preLoading(state, action);
        } else if (action.payload.data.message != 'Network Error') {
          state.error = action.payload.data;
        }
      })
      .addCase(loginUserPreHTTP.fulfilled, function (state, action) {
        state.isLoading = !state.isLoading;
        if (action.payload.data.message == 'Ok') {
          preLoading(state, action);
        } else if (action.payload.data.message != 'Network Error') {
          state.error = action.payload.data;
        }
      })
      .addCase(UpUserPreHTTP.fulfilled, function (state, action) {
        state.isLoading = !state.isLoading;
        if (action.payload.data.message == 'Ok') {
          preLoading(state, action);
        } else if (action.payload.data.message != 'Network Error') {
          state.error = action.payload.data;
        }
      })
      .addCase(coderUserHTTP.fulfilled, function (state, action) {
        setDone(state, action);
        state.isLoading = false;
      })
      .addCase(addCodeUserHTTP.fulfilled, function (state, action) {
        alert(action.payload);
        state.code = action.payload;
      });
  },
});

export const checkUserHTTP = createAsyncThunk<ResponseUserT, undefined>(
  'user/checkUserHTTP',
  async function () {
    try {
      const { data } = await $authHost.get('api/user/check');

      if (data.message === 'Ok') {
        localStorage.setItem('jwtgym', JSON.stringify(data.token));
      }
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;

        return data;
      }
    }
  }
);

export const loginUserPreHTTP = createAsyncThunk<any, UserLoginSliceT>(
  'user/loginUserPreHTTP',
  async function ({ body }) {
    try {
      const { data } = await $host.post('api/user/login', {
        ...body,
      });

      if (data.message == 'Ok') {
        return { data };
      }
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const { data } = err.response;
        return { data: data || err, login: true };
      } else {
        return err;
      }
    }
  }
);

export const UpUserPreHTTP = createAsyncThunk<any, UserLoginSliceT>(
  'user/UpUserPreHTTP',
  async function ({ body }) {
    try {
      const { data } = await $host.post('api/usercheck/add', {
        ...body,
      });

      if (data.message == 'Ok') {
        return data;
      }
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        return { data: data || err, login: false };
      } else {
        return err;
      }
    }
  }
);

// last fase =====================

export const addCodeUserHTTP = createAsyncThunk<any, undefined>(
  'user/addCodeUserHTTP',
  async function () {
    try {
      const { data } = await $host.get('api/usercheck/validcode');
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);
export const coderUserHTTP = createAsyncThunk<ResponseUserT, undefined>(
  'user/coderUserHTTP',
  async function (_, { getState }) {
    try {
      const state = getState() as any;
      await $host.get('api/user/deletecookie');
      const { data } = await $host.post('api/user/registration', {
        ...state.user.predUser.user,
      });
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const updateUserHTTP = createAsyncThunk<
  ResponseUserT,
  UserRegistrationT
>('user/updateUserHTTP', async function (dataUser) {
  try {
    const { data } = await $authHost.patch('api/user/update', dataUser);
    return data;
  } catch (error) {
    console.log(error);
  }
});

export const updatepassUserHTTP = createAsyncThunk<any, UserLoginSliceT>(
  'user/updatepassUserHTTP',
  async function ({ body }) {
    try {
      const { data } = await $authHost.patch('api/user/updatepass', body);
      if (data.message == 'Ok') {
        return { data };
      }
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        return { data: data || err, login: false };
      }
    }
  }
);

export const {
  codeLoginUser,
  clearCodeUser,
  changePageUser,
  exitUser,
  errorChange,
} = userSlice.actions;

export default userSlice.reducer;
