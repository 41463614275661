import React, { useEffect, Suspense } from 'react';
import { checkUserHTTP, useAppDispatch, useAppSelector } from './store';
import { AppRouter } from './Router/index';
import { addEventScroll, removeEventScroll } from './jsFunction/index';
import { favoriteAll } from './store/goodsSlices/favoriteSlice';
import { basketAll } from './store/goodsSlices/basketSlice';

import { Loading } from './components';
import PortalCont from './components/UI/Portal/PortalCont';

import 'swiper/css/bundle';
import './style/abstracts/_sppiner.scss';
import './style/variable.scss';
import './style/base.scss';

const App: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector((store) => store.user);

  const fetching = async () => {
    await dispatch(checkUserHTTP());
  };

  useEffect(() => {
    fetching();
  }, []);

  useEffect(() => {
    if (isAuth) {
      dispatch(favoriteAll());
      dispatch(basketAll());
    }
  }, [isAuth]);

  useEffect(function () {
    addEventScroll();
    return () => removeEventScroll();
  }, []);

  return (
    <Suspense fallback={<Loading name='all' />}>
      <PortalCont>
        <AppRouter />
      </PortalCont>
    </Suspense>
  );
};

export default App;
