import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import BodyLockorUn from '../../jsFunction/BodyLockorUn';

type InitialT = {
  isActive: boolean;
  component: 'free' | 'login' | 'discont' | '';
  subCom?: string;
};

const initialState: InitialT = {
  isActive: false,
  component: '',
  subCom: '',
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    changeModal(state, action: PayloadAction<InitialT>) {
      BodyLockorUn.bodyLock();
      state.isActive = action.payload.isActive;
      state.component = action.payload.component;
      if (action.payload.subCom) {
        state.subCom = action.payload.subCom;
      }
    },
    closeModel(state) {
      state.isActive = false;
      BodyLockorUn.bodyUnLock();
    },
    closeThenModel(state) {
      state.component = '';
      state.subCom = '';
    },
  },
});

export const { changeModal, closeModel, closeThenModel } = modalSlice.actions;

export default modalSlice.reducer;
