import { NumberValue } from '../../../../../../interface/ui.interface';

class BackSpace {
  el: HTMLInputElement;

  constructor(
    public setCode: any,
    public e: React.KeyboardEvent<HTMLInputElement>,
    public code: NumberValue[]
  ) {
    this.el = this.e.target as HTMLInputElement;
    this.code = code;
    this.setCode = setCode;
  }

  addNewValue = (): void => {
    this.setCode(
      [...this.code].map((item) =>
        item.name == this.el.name
          ? {
              val: '',
              name: item.name,
            }
          : item
      )
    );
  };

  changeFocus = (ignor: string, num: number): void => {
    if (this.el.name != ignor) {
      const elem = document.querySelector(
        `input[name='${num}']`
      ) as HTMLElement;
      elem.focus();
    }
  };

  back() {
    if (this.e.key == 'Backspace') {
      this.addNewValue();
      this.changeFocus('1', +this.el.name - 1);
    }
  }
}

export default BackSpace;
