import {
  BtnLoading,
  Button,
  Input,
  InputPassword,
  Loading,
} from '../../../../../../components';
import { SignInputAll } from '../../../../../../interface/ui.interface';
import { UserLoginT } from '../../../../../../store';

import './style/EnterBody.scss';

const Body: React.FC<SignInputAll<UserLoginT>> = ({
  setValue,
  register,
  errors,
  loading,
}): JSX.Element => {
  return (
    <div className='enterbody'>
      <div className='enterbody__body'>
        <Input
          req={true}
          register={register}
          title='Login / Email'
          type='text'
          name='email'
          textFiled='Login / Email'
          errors={errors}
          setValue={setValue}
        />
        <InputPassword
          req={true}
          title='Password'
          name='password'
          register={register}
          type='password'
          textFiled='Your password'
          setValue={setValue}
          errors={errors}
        />
      </div>
      <div className='enterbody__footer'>
        <p className='enterbody__forget mini'>Forgot password</p>
        <div className='enterbody__btn'>
          {loading ? (
            <BtnLoading>
              <Loading />
            </BtnLoading>
          ) : (
            <Button name='sign'>
              <p className='mini'>Next</p>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Body;
