import React, { useEffect, useState, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TwoFactor } from '../TwoFactor/TwoFactor';
import Body from './components/EnterBody/Body';
import {
  UserLoginT,
  clearCodeUser,
  codeLoginUser,
  errorChange,
  loginUserPreHTTP,
  useAppDispatch,
  useAppSelector,
} from '../../../../store';
import { ContextPortal } from '../../../../components/UI/Portal/PortalCont';
import { $, renderInput, validateUserValue } from '../../../../components';
import { changeModal } from '../../../../store/modalSlice/modalSlice';
import { productItogHTTP } from '../../../../store/goodsSlices/devicesAPI/deviceAPI';
import { useLocation } from 'react-router-dom';

import './style/SignIn.scss';

export type SignInUpConf = {
  close: () => void;
};

const SignIn: React.FC<SignInUpConf> = ({ close }): JSX.Element => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
  } = useForm<UserLoginT>();
  const { toggleAdd } = useContext(ContextPortal);
  const [value, setValues] = useState('');
  const dispatch = useAppDispatch();
  const { isLoading, page, code, error } = useAppSelector(
    (store) => store.user
  );
  const params = useLocation();

  const fetching = async (body: UserLoginT) => {
    if (page.includes('first')) {
      await dispatch(loginUserPreHTTP({ body }));
    } else if (page.includes('last')) {
      if (+code === +value) {
        dispatch(codeLoginUser());
        close();
        toggleAdd({
          en: 'You are logged in',
          ru: 'Вы успешно авторизовались',
          time: 2000,
        });
        if (params.pathname.includes('shop')) {
          dispatch(productItogHTTP());
        }
      } else {
        $('.numberCode input').add('err');
      }
    }
  };

  useEffect(() => {
    if (!isLoading && 'message' in error) {
      validateUserValue({ data: error, setError, login: true });
      setTimeout(() => dispatch(errorChange()));
    }
  }, [error]);

  const onSubmit: SubmitHandler<UserLoginT> = (data) => {
    fetching(data);
  };

  useEffect(() => {
    setTimeout(() => {
      renderInput();
    }, 500);
  }, []);

  return (
    <form className='signin' onSubmit={handleSubmit(onSubmit)}>
      {page.includes('first') ? (
        <Body
          loading={isLoading}
          setValue={setValue}
          register={register}
          errors={errors}
        />
      ) : (
        <TwoFactor to='first' setValue={setValues} />
      )}
    </form>
  );
};

export default SignIn;
