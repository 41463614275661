import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonType } from '../../../interface/ui.interface';
import './style/Button.scss';

export const BtnArrow: React.FC<ButtonType> = ({
  name = 'none',
  children,
  ...props
}) => {
  return (
    <div {...props} className={`btnarrow ${name}`}>
      <div className='btnarrow__arrow'>
        <span></span>
        <span></span>
      </div>
      <p className='btnarrow__btn'>{children}</p>
    </div>
  );
};

export const Button: React.FC<ButtonType> = ({ name, children, ...props }) => {
  return (
    <button {...props} type='submit' className={`btn ${name}`}>
      {children}
    </button>
  );
};

type HOKBtn = {
  router: string;
  clouse?: boolean;
};

export const Wrapper = <T extends ButtonType & HOKBtn>(
  PassedComponent: React.ComponentType<T>
) => {
  const router = useNavigate();
  return (props) => {
    const toRoof = () => {
      document.documentElement?.scrollIntoView(true);
      router(props?.router);
    };
    return <PassedComponent onClick={toRoof} {...(props as T)} />;
  };
};
