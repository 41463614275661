import { createPortal } from 'react-dom';
import { useEffect, useContext } from 'react';
import { ReactComponent as X } from 'img/icons/x.svg';
import { ReactComponent as Like } from 'img/icons/heart.svg';
import { ReactComponent as Bag } from 'img/icons/bucket.svg';
import Paragraph from '../Paragraph/Paragraph';
import { $ } from 'components/index';
import { ContextPortal } from './PortalCont';

import './Portal.scss';

export type PortalT = {
  ru?: string;
  en: string;
  id: number;
  err?: string;
  first?: string;
};

const Portal: React.FC<PortalT> = ({
  ru,
  en,
  err = '',
  id,
  first,
}): JSX.Element => {
  const { closePortal } = useContext(ContextPortal);

  useEffect(() => {
    leftPadding();

    setTimeout(() => {
      $('.portal' + id).add('show');
    });
  }, []);

  useEffect(() => {
    window.addEventListener('resize', leftPadding);
    return () => {
      window.removeEventListener('resize', leftPadding);
    };
  }, []);

  function leftPadding() {
    $('.portal').style(
      'right',
      $('.header__wrap').elem.getBoundingClientRect().left + 'px'
    );
  }

  return (
    <>
      {createPortal(
        <div className={`portal portal${id} ${err} ${first}`}>
          <div className='portal__body'>
            <div className='portal__text'>
              {(err === 'like' || err === 'likeDelete') && (
                <div onClick={() => closePortal(id)} className='portal__basket'>
                  <Like />
                </div>
              )}
              {(err === 'basketAddPort' || err === 'basketDelete') && (
                <div onClick={() => closePortal(id)} className='portal__basket'>
                  <Bag />
                </div>
              )}
              <Paragraph name='midle' ru={ru} en={en} />
            </div>
            <div onClick={() => closePortal(id)} className='portal__close'>
              <X />
            </div>
          </div>
        </div>,
        $('.portal__container').elem
      )}
    </>
  );
};
export default Portal;
