import { createAsyncThunk } from '@reduxjs/toolkit';
import { $host } from '../../http';
import axios, { AxiosError } from 'axios';
import { goodsListT, setTypeConfig } from './goodsList.config';
import { ProductT, initialStateHTTPT } from '../goods.config';
import { FieldValues } from 'react-hook-form';

export type GetProductT = {
  device: ProductT;
  message: string;
};

export const getListGoods = createAsyncThunk<
  goodsListT,
  { typeId: string; page: string }
>('devicelist/getListGoods', async function ({ typeId, page }) {
  try {
    const response = await $host.post('api/device/goods', { typeId, page });
    return response.data;
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      console.log(err.message);
    }
  }
});

export const productTypesHTTP = createAsyncThunk<initialStateHTTPT, undefined>(
  'devicelist/productTypesHTTP',
  async function () {
    try {
      const { data } = await $host.get('api/device/types');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        throw data;
      }
    }
  }
);

export const deleteDeviceHTTP = createAsyncThunk<void, { id: number }>(
  'devicelist/deleteDeviceHTTP',
  async function ({ id }) {
    try {
      await $host.delete(`api/device/delete/${id}/false`);
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        console.log(err.message);
      }
    }
  }
);

export const getProductSelectHTTP = createAsyncThunk<
  GetProductT,
  { id: string | number }
>('devicelist/getProductSelectHTTP', async function ({ id = 0 }) {
  try {
    const { data } = await $host.get('api/device/' + id);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      return data;
    }
  }
});

export async function setImgProductHTTP(img) {
  try {
    const { data } = await $host.post(
      `api/img`,
      { ...img },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
    return data;
  } catch (error) {
    const err = error as AxiosError;

    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      console.log(data);
    }
  }
}

export async function changeProductHTTP(
  objdata: FieldValues,
  id: number,
  img: string,
  files: FileList
) {
  try {
    await $host.patch(
      `api/img`,
      { id, img, ...files },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );

    const { data } = await $host.patch(`api/device`, {
      id,
      name: objdata.name,
      price: objdata.price,
      sale: objdata.sale,
      brandId: objdata.brand,
      typeId: objdata.category,
      colorId: objdata.color,
      info: objdata.information,
    });

    return data;
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      return data;
    }
  }
}

export async function setProductHTTP(objdata, imgId) {
  try {
    const { data } = await $host.post(`api/device`, {
      name: objdata.name,
      price: objdata.price,
      sale: objdata.sale,
      brandId: objdata.brand,
      typeId: objdata.category,
      colorId: objdata.color,
      deviceimgId: imgId,
      info: objdata.information,
    });

    return data;
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      console.log(data);
    }
  }
}

// * set fileds ==================================================

export const setBORTSelectHTTP = createAsyncThunk<
  setTypeConfig,
  { name: string; title: string }
>('devicelist/setBORTSelectHTTP', async function ({ name, title }) {
  try {
    const path = title
      .split('')
      .map((item2: string) => {
        return item2.toLowerCase();
      })
      .join('');

    const { data } = await $host.post('api/' + path + '/', {
      name,
    });
    return { ...data, title };
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      throw data;
    }
  }
});

export const setColorSelectHTTP = createAsyncThunk<
  setTypeConfig,
  { name: string; color: string }
>('devicelist/setColorSelectHTTP', async function ({ name, color }) {
  try {
    const { data } = await $host.post('api/color/', {
      name,
      color,
    });
    return { ...data, title: 'color' };
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      throw data;
    }
  }
});

export const deleteTypeSelectHTTP = createAsyncThunk<
  setTypeConfig,
  { id: number; title: string }
>('devicelist/deleteTypeSelectHTTP', async function ({ title, id }) {
  try {
    const { data } = await $host.delete('api/' + title + '/' + id);
    return { ...data, title };
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      throw data;
    }
  }
});
