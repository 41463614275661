import { SingUpInputFiled } from '../../../../../../interface/ui.interface';
import { UserRegistrationT, useAppSelector } from '../../../../../../store';
import FirstPageUp from './FirstPageUp';
import SecondPageUp from './SecondPageUp';
import './style/UpBody.scss';

const Body: React.FC<SingUpInputFiled<UserRegistrationT>> = ({
  setValue,
  register,
  errors,
  getValues,
  loading,
}): JSX.Element => {
  const { page } = useAppSelector((state) => state.user);
  return (
    <div className='upbody'>
      {page.includes('first') && (
        <FirstPageUp
          setValue={setValue}
          register={register}
          errors={errors}
          loading={loading}
        />
      )}

      {page.includes('second') && (
        <SecondPageUp
          setValue={setValue}
          register={register}
          errors={errors}
          getValues={getValues}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Body;
