import { PayloadAction } from '@reduxjs/toolkit';
import {
  ProductT,
  ShopInitialT,
  actionDeviceAllT,
  initialStateHTTPT,
} from '../goods.config';
import { RootState } from '../../store';
import axios, { AxiosError } from 'axios';
import { $host } from '../../http';

export function addDeviceItog(
  state: ShopInitialT,
  action: PayloadAction<{ rows: ProductT[] }>
) {
  const searchParams = new URLSearchParams(window.location.search);
  const { limit } = state;
  const { rows } = action.payload;
  state.goodsSort = rows;
  const page = searchParams.get('page') || '1';

  if (rows) {
    state.goods = rows.reduce((prev: ProductT[], item, index) => {
      if (index >= +page * +limit - +limit && prev.length < +limit) {
        prev.push(item);
      }
      return prev;
    }, []);
  }
}

export function changePageGoodsDevice(state: ShopInitialT) {
  const { limit, page } = state;
  state.goods = state.goodsSort.reduce((prev: ProductT[], item, index) => {
    if (index >= +page * +limit - +limit && prev.length < +limit) {
      prev.push(item);
    }
    return prev;
  }, []);
}

export async function buidItogDevice({
  state,
  typeNew = null,
}: {
  state: () => RootState;
  typeNew?: string | number | null;
}) {
  try {
    const searchParams = new URLSearchParams(window.location.search);
    const { device } = state() as RootState;
    const brand: number[] = [];
    const color: number[] = [];
    let sales = false;
    let filter = 'low';

    for (const [key, value] of searchParams.entries()) {
      if (key.startsWith('brand')) {
        brand.push(+value);
      } else if (key.startsWith('color')) {
        color.push(+value);
      } else if (key.startsWith('sale')) {
        sales = true;
      } else if (key.startsWith('filter')) {
        filter = value;
      }
    }

    const typeId = searchParams.get('type') || typeNew || device?.types[0]?.id;

    const { data } = await $host.post('api/device/all', {
      brandId: JSON.stringify(brand),
      colorId: JSON.stringify(color),
      typeId,
      sales,
      low: searchParams.get('l') || 0,
      tall: searchParams.get('t') || 1000,
      filter,
    });

    return data;
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      console.log(err.message);
    }
  }
}

export function handlerSortDevice(
  state: ShopInitialT,
  target: string | undefined
) {
  const arr = state.goodsSort;
  if (target == 'low') {
    arr.sort((a, b) => +a.price.replace('$', '') - +b.price.replace('$', ''));
  } else if (target == 'high') {
    arr.sort((a, b) => +b.price.replace('$', '') - +a.price.replace('$', ''));
  }
  state.goodsSort = arr;
}

export function caseItogDevice(
  state,
  action: PayloadAction<actionDeviceAllT | initialStateHTTPT>
) {
  const { sum, count } = action.payload;
  addDeviceItog(state, action);

  state.sum = +sum;
  state.pages =
    count > state.limit ? Math.ceil(+count / +state.limit).toString() : '0';
}
