import React from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { $, Button, Input } from '../../../components';
import { clearCodeUser, useAppDispatch } from '../../../store';
import {
  closeModel,
  closeThenModel,
} from '../../../store/modalSlice/modalSlice';

import './style/TryFree.scss';

export default function TryFree({ setActive }) {
  const dispatch = useAppDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({ mode: 'onBlur' });

  const onSubmit: SubmitHandler<FieldValues> = () => {
    setActive(false);
  };

  function close() {
    dispatch(closeModel());
    dispatch(clearCodeUser());
    $('.modal').elem.ontransitionend = function () {
      dispatch(closeThenModel());
      $('.modal').elem.ontransitionend = function () {
        return false;
      };
    };
  }

  return (
    <div className='tryfree'>
      <div className='tryfree__img'></div>
      <div className='tryfree__body'>
        <div className='tryfree__title'>
          <h1>Try free your first class</h1>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className='tryfree__input'>
          <Input
            req={true}
            register={register}
            type='text'
            name='email'
            textFiled='Email'
            errors={errors}
            setValue={setValue}
          />
          <div className='tryfree__btn'>
            <Button name='local'>
              <p className='mini'>try free</p>
            </Button>
          </div>
        </form>
        <div className='tryfree__no'>
          <p onClick={close} className='mini'>
            No, Thanks!
          </p>
        </div>
      </div>
    </div>
  );
}
