import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { $host } from '../../http';
import axios, { AxiosError } from 'axios';
import { DeleteChangT, RatingChangT, RatingtInitialT } from '../../store.conf';
import { TypesConfig } from '../goods.config';

const initialState: RatingtInitialT = {
  rating: [],
  isLoading: false,
};

const ratingSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    setRating(state, action: PayloadAction<{ rating: TypesConfig[] }>) {
      state.rating = action.payload.rating;
    },
  },
  extraReducers: function (builder) {
    builder
      .addCase(changeRatingHTTP.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(
        changeRatingHTTP.fulfilled,
        function (state, action: PayloadAction<RatingChangT>) {
          state.isLoading = false;
          console.log(action.payload);

          if (action.payload.message === 'Ok')
            state.rating = action.payload.rating;
        }
      )
      .addCase(
        deleteRatingHTTP.fulfilled,
        function (state, action: PayloadAction<DeleteChangT>) {
          if (action.payload.message === 'Ok')
            state.rating = action.payload.device.ratings;
        }
      );
  },
});

export const changeRatingHTTP = createAsyncThunk<
  RatingChangT,
  { userId: string | number; deviceId: string | number; quantity: number }
>('comment/changeRatingHTTP', async function ({ userId, deviceId, quantity }) {
  try {
    const { data } = await $host.get('api/rating', {
      params: {
        userId,
        deviceId,
        quantity,
      },
    });
    return data;
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      return data;
    }
  }
});

export const deleteRatingHTTP = createAsyncThunk<
  DeleteChangT,
  { userId: string | number; deviceId: string | number }
>('comment/deleteRatingHTTP', async function ({ userId, deviceId }) {
  try {
    const { data } = await $host.delete('api/rating', {
      params: {
        userId,
        deviceId,
      },
    });
    return data;
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      return data;
    }
  }
});

export const { setRating } = ratingSlice.actions;

export default ratingSlice.reducer;
