import { ClassJQ } from './ClassJQ';
import actionJQ from './actionJQ';

export class NeighborJQ extends ClassJQ {
  constructor(arg: string | HTMLElement | (string | HTMLElement)[]) {
    super(arg);
    this.eachPrev = this.eachPrev.bind(this);
    this.eachNext = this.eachNext.bind(this);
    this.siblings = this.siblings.bind(this);
  }

  prev(): NeighborJQ {
    return actionJQ(this.eachPrev());
  }

  next(): NeighborJQ {
    return actionJQ(this.eachNext());
  }

  siblings(): NeighborJQ {
    const siblings: HTMLElement[] = [];
    siblings.push(...this.eachPrev());
    siblings.push(...this.eachNext());
    return actionJQ(siblings);
  }

  private eachNext(): HTMLElement[] {
    const siblings: HTMLElement[] = [];
    for (const item of this.el) {
      let target = item;
      while (target.nextSibling) {
        target = target.nextSibling as HTMLElement;
        target.nodeType == 1 && siblings.push(target);
      }
    }
    return siblings;
  }

  private eachPrev(): HTMLElement[] {
    const siblings: HTMLElement[] = [];

    for (const item of this.el) {
      let target = item;
      while (target.previousSibling) {
        target = target.previousSibling as HTMLElement;

        target.nodeType == 1 && siblings.push(target);
      }
    }

    return siblings;
  }
}
