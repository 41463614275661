import { ProductBasketT } from '../../../store/goodsSlices/goods.config';

export function countBasket(products: ProductBasketT[]) {
  return products.reduce((prev, item) => {
    return (prev += item.quantity);
  }, 0);
}

export function sumBasket(products: ProductBasketT[]) {
  return products
    .reduce((sum, item) => {
      if (item.sale > 0) {
        return (sum += +(
          +item.price * item.quantity -
          ((+item.price * item.quantity) / 100) * +item.sale
        ));
      }
      return (sum += +item.price * item.quantity);
    }, 0)
    .toFixed(0);
}

export function sumSaleBasket(
  products: ProductBasketT[],
  sale: string | number
) {
  return products
    .reduce((sum, item) => {
      if (item.sale > 0) {
        sum += +(
          +item.price * item.quantity -
          (+item.price / 100) * (item.sale + +sale)
        );
      } else {
        sum +=
          +item.price * item.quantity -
          ((+item.price * item.quantity) / 100) * +sale;
      }
      return sum;
    }, 0)
    .toString()
    .replace(/^(.{0,4}).*/, '$1')
    .replace(/\.$/, '');
}

export function countItemBasket(products: ProductBasketT[], id: number) {
  return products.filter((item) => item.id == id)[0]?.quantity || 0;
}
