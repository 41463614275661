import { UseFormSetValue, FieldValues } from 'react-hook-form';

export const focus = (e: React.FocusEvent<HTMLInputElement>) => {
  e.target.removeAttribute('readOnly');
  const target = e.target
    ?.closest('.inputFiled__block')
    ?.querySelector('.inputFiled__text');

  if (e.type != 'blur') {
    target?.classList.add('focus');
    target?.closest('.inputFiled')?.classList.add('focus');
  } else {
    if (e.target?.value == '') {
      target?.classList.remove('focus');
      target?.classList.add('old');
      target?.closest('.inputFiled')?.classList.remove('focus');
    }
  }
};

export const change = (
  e: React.ChangeEvent<HTMLInputElement>,
  setValue: UseFormSetValue<FieldValues>,
  name: string
) => {
  if (e.target?.value) {
    e.target
      .closest('.inputFiled__block')
      ?.querySelector('.inputFiled__text')
      ?.classList.remove('old');
  } else {
    e.target
      .closest('.inputFiled__block')
      ?.querySelector('.inputFiled__text')
      ?.classList.add('old');
  }
  setValue(name, e.target.value);
};

export const renderInput = () => {
  const arr = Array.from(document.querySelectorAll('.inputFiled__text'));
  for (const item of arr) {
    if ((item.nextElementSibling as HTMLInputElement)?.value) {
      item?.classList.remove('old');
    } else {
      item?.classList.add('old');
    }
  }
};
