import React from 'react';
import { Navigate } from 'react-router-dom';
import { links } from './links';
import { Price } from '../Pages/Price/Price';

const AbountUs = React.lazy(() => import('../Pages/AbountUs/AbountUs'));
const Contacts = React.lazy(() => import('../Pages/AbountUs/Contacts'));
const Schedule = React.lazy(() => import('../Pages/Schedule/Schedule'));
const Shop = React.lazy(() => import('../Pages/Shop/Shop'));
const OneProduct = React.lazy(() => import('../Pages/ItemPage/OneProduct'));
const Basket = React.lazy(() => import('../Pages/Basket/Basket'));
const LikesGoods = React.lazy(() => import('../Pages/LikesGoods/LikesGoods'));
const UserSelector = React.lazy(() => import('../Pages/User/UserSelector'));

export const publicRoutes = [
  { path: '*', element: <Navigate to='/' replace /> },
  { path: links.BASKET_ROUTER, element: <Basket /> },
  { path: links.SHOP_ROUTER, element: <Shop /> },
  { path: links.SCHEDULE, element: <Schedule /> },
  { path: links.PAGEITEM, element: <OneProduct /> },
  { path: links.PAGEITEBAS, element: <OneProduct /> },
  { path: links.PRICE, element: <Price /> },
  { path: links.GYM, element: <Contacts /> },
  { path: links.ABOUTUS, element: <AbountUs /> },
  { path: links.LIKE, element: <LikesGoods /> },
  { path: links.USER, element: <UserSelector /> },
  { path: links.ADMINGOODS, element: <UserSelector /> },
  { path: links.ADMINGOODSADD, element: <UserSelector /> },
  { path: links.ADMINITEM, element: <UserSelector /> },
  { path: links.USERPROFILE, element: <UserSelector /> },
  { path: links.ADMINUSERS, element: <UserSelector /> },
  { path: links.USERPASS, element: <UserSelector /> },
];
