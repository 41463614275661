import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { $host } from '../http';
import { BasketSliceT, ComeBasketFavoriteT } from './goods.config';
import axios, { AxiosError } from 'axios';
import { links } from '../../Router';

const initialState: BasketSliceT = {
  products: [],
  sale: 0,
  loading: false,
};

const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    setSale(state, action: PayloadAction<{ sale: number }>) {
      state.sale = action.payload.sale;
    },
  },
  extraReducers: function (b) {
    b.addCase(basketAll.fulfilled, addConf)
      .addCase(append.fulfilled, addConf)
      .addCase(increment.fulfilled, addConf)
      .addCase(decrement.fulfilled, addConf)
      .addCase(
        removeBasketHTTP.fulfilled,
        function (state, action: PayloadAction<any>) {
          const { devices } = action.payload;

          addConf(state, action);

          const arrowgo = document.querySelector(
            '.basket__price'
          ) as HTMLElement;

          devices.length < 1 &&
            window.history.pushState(null, links.API_URL + 'shop');

          if (devices.length == 1) {
            arrowgo?.classList.remove('bottom');
            arrowgo?.classList.remove('show');
            arrowgo?.classList.remove('showtwo');
          }
        }
      )
      .addCase(clearBasketHTTP.fulfilled, clearDone);
  },
});

function clearDone(state) {
  state.products = [];
}

function addConf(state, action: PayloadAction<ComeBasketFavoriteT>) {
  if (action.payload.devices) {
    state.products = action.payload.devices;
    state.loading = false;
  }
}

export const basketAll = createAsyncThunk<ComeBasketFavoriteT, undefined>(
  'basket/basketAll',
  async function () {
    try {
      const { data } = await $host.get('api/basket/');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        return data;
      }
    }
  }
);

export const append = createAsyncThunk<ComeBasketFavoriteT, { id: number }>(
  'basket/append',
  async function ({ id }) {
    try {
      const { data } = await $host.put(`api/basket/product/${id}/append/1`);
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        return data;
      }
    }
  }
);

export const increment = createAsyncThunk<ComeBasketFavoriteT, { id: number }>(
  'basket/increment',
  async function ({ id }) {
    try {
      const { data } = await $host.put(`api/basket/product/${id}/increment/1`);
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        return data;
      }
    }
  }
);

export const decrement = createAsyncThunk<ComeBasketFavoriteT, { id: number }>(
  'basket/decrement',
  async function ({ id }) {
    try {
      const { data } = await $host.put(`api/basket/product/${id}/decrement/1`);
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        return data;
      }
    }
  }
);

export const removeBasketHTTP = createAsyncThunk<
  ComeBasketFavoriteT,
  { id: number }
>('basket/removeBasketHTTP', async function ({ id }) {
  try {
    const { data } = await $host.put(`api/basket/product/${id}/remove`);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      return data;
    }
  }
});

export const clearBasketHTTP = createAsyncThunk<ComeBasketFavoriteT, undefined>(
  'basket/clearBasketHTTP',
  async function () {
    try {
      const { data } = await $host.put(`api/basket/clear`);
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        return data;
      }
    }
  }
);

export const { setSale } = basketSlice.actions;

export default basketSlice.reducer;
