import './style/Title.scss';

export function Title({ name }) {
  return (
    <div className='title'>
      <span></span>
      <h1>{name}</h1>
      <span></span>
    </div>
  );
}
