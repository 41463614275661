import MyImage from 'img/header/logo.jpg';
import React, { useEffect } from 'react';
import { ReactComponent as Inst } from 'img/icons/inst.svg';
import { ReactComponent as Twi } from 'img/icons/twitter.svg';
import { ReactComponent as Face } from 'img/icons/face.svg';
import { ReactComponent as Arrow } from 'img/icons/arrow.svg';
import { useLocation } from 'react-router-dom';
import { $, changeMargin } from 'components/index';
import './style/Footer.scss';

export function Footer() {
  const goroof = (e: React.MouseEvent) => {
    e.preventDefault();
    document.documentElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  const path = useLocation();

  useEffect(() => {
    if (/admin|user|price|schedule|contacts/.test(path.pathname)) {
      $('.footer__inlinear').add('none');
    } else {
      $('.footer__inlinear').remove('none');
    }
  });

  useEffect(() => {
    $('.goroof').remove('show');
    if (!('documentMode' in window.document)) changeMargin();
  }, [path.pathname]);

  return (
    <>
      <section className='goroof' onClick={goroof}>
        <div className='goroof__arrow '>
          <Arrow />
        </div>
      </section>

      <footer
        className={`footer   ${
          !('documentMode' in window.document) ? ' lock-padding' : ''
        }`}
      >
        <div className='footer__inlinear '></div>
        <div className='container'>
          <div className='footer__body '>
            <div className='footer__logo'>
              <div className='footer__logoImg'>
                <img src={MyImage} alt='' />
              </div>
              <div translate='no' className='footer__logoText'>
                fight
                <br /> gym
              </div>
            </div>
            <div className='footer__text'>
              <p className='micro'>© 2006–2022 FightGym.com</p>

              <p className='mini'>
                The use of any site materials without the consent of the
                administration is prohibited.
              </p>

              <p className='mini'>646-747-4660</p>
            </div>
            <div className='footer__items'>
              <div className='footer__contacts'>
                <p className='mini'>
                  <a href='mailto:contact@fightgym.com'>Contact us</a>
                </p>
              </div>
              <div className='footer__icons'>
                <div className='footer__link'>
                  <Inst />
                </div>
                <div className='footer__link'>
                  <Twi />
                </div>
                <div className='footer__link'>
                  <Face />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
