import axios, { AxiosError } from 'axios';
import { CommentInitialT } from '../store.conf';
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { $host } from '../http';

const initialState: CommentInitialT = {
  comments: [],
  isLoading: true,
};

function changeComment(state, action) {
  if (action.payload.message === 'Ok') {
    state.comments = action.payload.comment.sort(function (a, b) {
      return new Date(a.date) > new Date(b.date) ? a : b;
    });
  }
}

const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    setComment(
      state,
      action: PayloadAction<Pick<CommentInitialT, 'comments'>>
    ) {
      state.comments = action.payload.comments;
    },
  },

  extraReducers: function (builder) {
    builder
      .addCase(getAllCommentHTTP.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(
        getAllCommentHTTP.fulfilled,
        function (state, action: PayloadAction<any>) {
          state.isLoading = false;
          changeComment(state, action);
        }
      )
      .addCase(deleteCommentHTTP.fulfilled, changeComment);
  },
});

export const getAllCommentHTTP = createAsyncThunk<
  Pick<CommentInitialT, 'comments'>,
  { id?: string | number }
>('comment/getAllCommentHTTP', async function ({ id }) {
  try {
    const { data } = await $host.get('api/comment/' + id);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      return data;
    }
  }
});

export const deleteCommentHTTP = createAsyncThunk<
  Pick<CommentInitialT, 'comments'>,
  {
    userId: string | number;
    commentId: string | number;
    deviceId: string | number;
  }
>(
  'comment/deleteCommentHTTP',
  async function ({ userId, commentId, deviceId }) {
    try {
      const { data } = await $host.delete(
        `api/comment/${commentId}/${userId}/${deviceId}`
      );
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        return data;
      }
    }
  }
);

export default commentSlice.reducer;
