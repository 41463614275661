import React, { FunctionComponent, useContext } from 'react';
import { ReactComponent as Plus } from '../../../../img/icons/plus.svg';
import { ReactComponent as Minus } from '../../../../img/icons/minus.svg';
import { btnHOCConfig, TypeBtn } from './Btn.conf';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { countItemBasket } from '../../../../Pages/Basket/components/basketFunction';
import {
  append,
  decrement,
  increment,
} from '../../../../store/goodsSlices/basketSlice';
import {
  ProductBasketT,
  ProductT,
} from '../../../../store/goodsSlices/goods.config';

import './style/ProductBtn.scss';
import { ContextPortal } from '../../Portal/PortalCont';

export const ProductBtn = <T extends ProductBasketT | ProductT>({
  product,
  show,
  item,
  handleClick,
}: TypeBtn<T>): JSX.Element => {
  const { products } = useAppSelector((state) => state.basket);
  const { isAuth } = useAppSelector((state) => state.user);
  return (
    <div
      className={
        show
          ? `product__addbasket productbtn show ${item}`
          : `product__addbasket productbtn ${item}`
      }
    >
      {!isAuth || countItemBasket(products, product?.id || 0) <= 0 ? (
        <div className='productbtn__btnold' data-plus onClick={handleClick}>
          <p className='big'>Add to cart</p>
        </div>
      ) : (
        <>
          <div className='productbtn__btn' data-minus onClick={handleClick}>
            <Minus />
          </div>
          <div data-count className='productbtn__numberBasket'>
            {countItemBasket(products, product?.id || 0)}
          </div>
          <div className='productbtn__btn' data-plus onClick={handleClick}>
            <Plus />
          </div>
        </>
      )}
    </div>
  );
};

export const BtnBasket = <T extends ProductBasketT | ProductT>({
  product,
  handleClick,
}: Pick<TypeBtn<T>, 'product' | 'handleClick'>): JSX.Element => {
  const { products } = useAppSelector((state) => state.basket);
  return (
    <div className='productbasket__addblock'>
      <div className='productbasket__btn' data-minus onClick={handleClick}>
        <Minus />
      </div>
      <div className='productbasket__count'>
        <p className='basketblock'>
          {countItemBasket(products, product?.id || 0)}
        </p>
      </div>
      <div className='productbasket__btn' data-plus onClick={handleClick}>
        <Plus />
      </div>
    </div>
  );
};

export const btnHOC = <T extends ProductBasketT | ProductT>(
  Component: FunctionComponent<TypeBtn<T>>
) => {
  const Wraper = ({
    model,
    product,
    ...props
  }: btnHOCConfig<T>): JSX.Element => {
    const { toggleAdd } = useContext(ContextPortal);
    const dispatch = useAppDispatch();
    const { isAuth } = useAppSelector((state) => state.user);
    const { products } = useAppSelector((state) => state.basket);
    const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
      if (isAuth) {
        if (product && 'id' in product) {
          if (!(e.target as HTMLElement).closest('div')?.dataset.minus) {
            if (countItemBasket(products, product.id || 0) > 0) {
              dispatch(increment({ id: product.id }));
            } else {
              dispatch(append({ id: product.id }));
            }
            toggleAdd({
              en: 'Product added in the bag',
              ru: 'Товар добавлен в корзину',
              err: 'basketAddPort',
              time: 2000,
            });
          } else {
            dispatch(decrement({ id: product.id }));
            toggleAdd({
              en: 'Product deleted in the bag',
              ru: 'Товар удален из корзины',
              err: 'basketDelete',
              time: 2000,
            });
          }
        }
      } else {
        if (model) model('login', 'in');
      }
    };

    return <Component product={product} handleClick={handleClick} {...props} />;
  };
  return Wraper;
};
