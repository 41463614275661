import { ReactComponent as Heart } from '../../../../../../img/icons/heart.svg';
import { ReactComponent as Gear } from '../../../../../../img/icons/gear.svg';
import { ReactComponent as Cart } from '../../../../../../img/icons/cart.svg';
import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as User } from '../../../../../../img/icons/user.svg';
import CountHeartOrBasket from './CountHeartOrBasket';
import { addClassBurger, showMenu } from '../../../../../../jsFunction/index';
import { useAppSelector } from '../../../../../../store';
import { links } from '../../../../../../Router';

export const ActiveUser = (): JSX.Element => {
  const { favorite, basket } = useAppSelector((store) => store);
  const user = useAppSelector((state) => state.user);
  const userData = user.user;
  const router = useNavigate();

  const pageBasket = (e: React.MouseEvent<HTMLElement>) => {
    if (basket.products.length > 0) {
      document.documentElement.scrollIntoView(true);
      removeBurger(e);

      router(links.BASKET_ROUTER);
    }
  };

  const goRoof = (e: React.MouseEvent<HTMLElement>) => {
    document.documentElement.scrollIntoView(true);
    removeBurger(e);
  };

  const removeBurger = (e) => {
    showMenu();
    addClassBurger(e);
  };

  return (
    <>
      <Link
        title='Profile'
        to='user/profile'
        className='blockicons__profile '
        onClick={goRoof}
      >
        <User />
      </Link>
      {'role' in userData && userData.role == 'Admin' && (
        <Link
          title='Settings'
          to='admin/goods'
          className='blockicons__profile '
          onClick={goRoof}
        >
          <Gear />
        </Link>
      )}
      <Link
        title='Favorite'
        onClick={goRoof}
        to='/favorites'
        className='blockicons__parentblock heartIcon'
      >
        <div className='blockicons__heart'>
          <Heart />
        </div>
        <CountHeartOrBasket count={favorite.products.length} />
      </Link>

      <div
        title='Basket'
        onMouseDown={pageBasket}
        className='blockicons__parentblock'
      >
        <div className='blockicons__basket'>
          <Cart />
        </div>
        <CountHeartOrBasket count={count(basket.products)} />
      </div>
    </>
  );
};

function count(products) {
  return products.reduce((prev, item) => {
    return (prev += item.quantity);
  }, 0);
}
