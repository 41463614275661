import React from 'react';
import { Login } from './components/Login/Login';
import { clearCodeUser, useAppDispatch, useAppSelector } from '../../store';
import TryFree from './TryFree/TryFree';
import Discount from './Discount/Discount';
import { closeModel, closeThenModel } from '../../store/modalSlice/modalSlice';
import { $ } from '../../components';

import './style/Modal.scss';

export const ModalActive = React.createContext<null | any>(null);

const Modal: React.FC = () => {
  const { isActive, component } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();
  const classOther =
    component.includes('discont') || component.includes('free') ? 'other' : '';

  const Components = {
    free: TryFree,
    login: Login,
    discont: Discount,
  };

  const Component = Components[component];

  function close() {
    dispatch(closeModel());
    dispatch(clearCodeUser());
    $('.modal').elem.ontransitionend = function () {
      dispatch(closeThenModel());
      $('.modal').elem.ontransitionend = function () {
        return false;
      };
    };
  }

  return (
    <>
      <div className={`modal__back ${isActive ? ' active ' : ''}`}></div>
      <div
        className={'modal ' + (isActive ? ' active ' : '')}
        onMouseDown={close}
      >
        <div className='modal__bg'>
          <div className={`modal__container ${classOther}`}>
            <div
              className={`modal__body `}
              onMouseDown={(e) => e.stopPropagation()}
            >
              {Component !== undefined && <Component />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
