export const links = {
  SHOP_ROUTER: '/shop',
  SCHEDULE: '/schedule',
  LOGIN_ROUTER: '/login',
  REGISTRATION_ROUTER: '/registration',
  BASKET_ROUTER: '/basket',
  DEVICE_ROUTER: '/device',
  USER: '/user',
  USERPROFILE: '/user/profile',
  USERPASS: '/user/pass',
  PRICE: '/price',
  GYM: '/contacts',
  LIKE: '/favorites',
  ADMINGOODS: '/admin/goods',
  ADMINITEM: '/admin/item/:id',
  ADMINGOODSADD: '/admin/item',
  ADMINUSERS: '/admin/users',
  PAGEITEM: '/shop/item/:id',
  PAGEITEBAS: '/basket/:id',
  ABOUTUS: '/contacts/:path',
  HOME_ROUTER: '/',
  //   API_URL: 'http://localhost:5500/',
  API_URL: 'https://club.proj.host/',
};
