import axios from 'axios';
import { links } from '../../Router/links';

const $host = axios.create({
  baseURL: links.API_URL,
  withCredentials: true,
  //   headers: {
  //     'Cache-Control': 'no-cache, no-store, must-revalidate',
  //   },
});

const $authHost = axios.create({
  baseURL: links.API_URL,
  withCredentials: true,
  //   headers: {
  //     'Cache-Control': 'no-cache, no-store, must-revalidate',
  //     Pragma: 'no-cache',
  //     Expires: '0',
  //   },
});

const interceptor = (config: any) => {
  config.headers.authorization = `Bearer ${JSON.parse(
    localStorage.getItem('jwtgym') || ''
  )}`;
  return config;
};

$authHost.interceptors.request.use(interceptor);

export { $host, $authHost };
