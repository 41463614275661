import React, { useEffect, useState } from 'react';
import NumberCode from './Components/NumberCode/NumberCode';
import Timer from './Components/Timer/Timer';
import { TwoFactorTypes } from '../../../../interface/ui.interface';
import { BtnLoading, Button, Loading } from '../../../../components';
import {
  addCodeUserHTTP,
  changePageUser,
  useAppDispatch,
  useAppSelector,
} from '../../../../store';

import './style/TwoFactor.scss';

export const TwoFactor: React.FC<TwoFactorTypes> = ({
  setValue,
  to,
}): JSX.Element => {
  const [times, setTimes] = useState<number>(15);
  const { isLoading, predUser, code } = useAppSelector((store) => store.user);
  const dispatch = useAppDispatch();
  const fetching = async () => {
    await dispatch(addCodeUserHTTP());
  };

  const back = () => {
    dispatch(changePageUser({ to }));
  };

  useEffect(() => {
    if (code == '') fetching();
  }, []);

  const requestNumber = () => {
    fetching();
    setTimes(15);
  };

  return (
    <div className='twofactor in'>
      <h3 className='twofactor__title'>Confirm Login</h3>
      <div className='twofactor__text'>
        <p className='number'>
          {` To the number ${
            'number' in predUser.user && predUser.user.number
          } Sent an SMS with a 4-digit confirmation code`}
        </p>
      </div>
      <Timer times={times} setTimes={setTimes} requestNumber={requestNumber} />
      <NumberCode setValue={setValue} />

      <div className='twofactor__footer'>
        <p onClick={back} className='mini twofactor__back'>
          {'< Back'}
        </p>
        <div className='twofactor__btn'>
          {isLoading ? (
            <BtnLoading>
              <Loading />
            </BtnLoading>
          ) : (
            <Button name='sign'>
              <p className='mini'>Next</p>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
