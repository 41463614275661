import { useAppDispatch } from '../../../../../../store';
import { changeModal } from '../../../../../../store/modalSlice/modalSlice';
import Paragraph from '../../../../../UI/Paragraph/Paragraph';

const Sign: React.FC = () => {
  const dispatch = useAppDispatch();

  function open(com, sub) {
    dispatch(changeModal({ isActive: true, component: com, subCom: sub }));
  }

  return (
    <ul className='blockicons__sign'>
      <li onClick={() => open('login', 'in')}>
        <a>
          <Paragraph name='link' ru='Войти' en='Sign in' />
        </a>
      </li>
      <li onClick={() => open('login', 'up')}>
        <a>
          <Paragraph name='link' ru='Регистрация' en='Sign up' />
        </a>
      </li>
    </ul>
  );
};

export default Sign;
