import { NumberValue } from '../../../../../../interface/ui.interface';

class ChangeFiled {
  el: HTMLInputElement;

  constructor(
    public setCode: any,
    public e: React.ChangeEvent<HTMLInputElement>,
    public code: NumberValue[]
  ) {
    this.el = this.e.target as HTMLInputElement;
    this.code = code;
    this.setCode = setCode;
  }

  addNewValue = (): void => {
    this.setCode(
      [...this.code].map((item) =>
        item.name == this.el.name
          ? {
              val: +this.el.value.length > 0 ? this.el.value : '',
              name: item.name,
            }
          : item
      )
    );
  };

  changeFocus = (ignor: string, num: number): void => {
    if (this.el.name != ignor) {
      const elem = document.querySelector(
        `input[name='${num}']`
      ) as HTMLElement;
      elem.focus();
    }
  };

  validate() {
    if (this.el.value.length == 1 && /^\d*$/.test(this.el.value)) {
      this.addNewValue();
      this.changeFocus('4', +this.el.name + 1);
    } else if (this.el.value.length > 1) {
      this.changeFocus('4', +this.el.name + 1);
    }
  }
}

export default ChangeFiled;
