import { useState, useEffect } from 'react';
import { $ } from 'components/index';
import { Loading } from '../Loading/Loading';
import './WinLoad.scss';

export const LoadingWin: React.FC<WinLoadT> = ({ children, isLoading }) => {
  const [winload, setwinload] = useState(true);

  const handlerload = function () {
    if (document.readyState == 'complete') {
      setwinload(false);
      $('body').remove('lock');
    } else {
      $('body').add('lock');
      setwinload(true);
    }
  };

  useEffect(() => {
    if (document.readyState == 'complete') {
      setwinload(false);
      $('body').remove('lock');
    }
    document.addEventListener('readystatechange', handlerload);

    return () => {
      document.removeEventListener('readystatechange', handlerload);
    };
  }, []);

  return (
    <>
      <div className={winload || isLoading ? 'winload load' : 'winload '}>
        <Loading name='all' />
      </div>
      {!isLoading && children}
    </>
  );
};

type WinLoadT = {
  children: React.ReactNode;
  isLoading: boolean;
};
