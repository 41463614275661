import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SelectorDeviceInitialT } from '../goods.config';
import {
  GetProductT,
  deleteTypeSelectHTTP,
  getListGoods,
  getProductSelectHTTP,
  productTypesHTTP,
  setBORTSelectHTTP,
  setColorSelectHTTP,
} from './deviceListAPI';
import { goodsListT, setTypeConfig } from './goodsList.config';

const initialState: SelectorDeviceInitialT = {
  goodsList: {
    rows: [],
    sum: '',
    count: '',
  },
  product: {},
  isLoading: true,
  isLoadingList: true,
  colors: [],
  brands: [],
  types: [],
  old: false,
};

const deviceSlice = createSlice({
  name: 'deficeSlice',
  initialState,
  reducers: {
    changeOldFiled(state, action: PayloadAction<{ old: boolean }>) {
      state.old = action.payload.old;
    },
  },
  extraReducers: function (b) {
    b.addCase(getListGoods.pending, function (state) {
      state.isLoadingList = true;
    })
      .addCase(getListGoods.fulfilled, done)
      .addCase(getProductSelectHTTP.pending, function (state) {
        state.product = {};
        state.isLoading = true;
      })
      .addCase(
        getProductSelectHTTP.fulfilled,
        function (state, action: PayloadAction<GetProductT>) {
          if (action.payload.message === 'Ok') {
            state.product = action.payload.device;
            state.isLoading = false;
          }
        }
      )
      .addCase(productTypesHTTP.fulfilled, function (state, action) {
        const { type, color, brand } = action.payload;

        if (action.payload.message === 'Ok') {
          state.colors = color;
          state.types = type;
          state.brands = brand;
        }

        state.isLoading = false;
      })
      .addCase(setBORTSelectHTTP.fulfilled, doneTypes)
      .addCase(setColorSelectHTTP.fulfilled, doneTypes)
      .addCase(deleteTypeSelectHTTP.fulfilled, doneTypes)
      .addMatcher(
        ({ type }) => /^devicelist\/.*?\/fulfilled$/.test(type),
        loadingend
      );
  },
});

function loadingend(state) {
  state.isLoading = false;
}

function doneTypes(state, action: PayloadAction<setTypeConfig>) {
  state.isLoadingList = false;
  const { title } = action.payload;
  if (action.payload.message === 'Ok') {
    if (title == 'brand') {
      state.brands = action.payload.body;
    } else if (title == 'category') {
      state.types = action.payload.body;
    } else if (title == 'color') {
      state.colors = action.payload.body;
    }
  }
}

function done(state, action: PayloadAction<goodsListT>) {
  state.isLoadingList = false;
  if (action.payload.message === 'Ok') {
    state.goodsList = action.payload.device;
  }
}

export const { changeOldFiled } = deviceSlice.actions;
export default deviceSlice.reducer;
