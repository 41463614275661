import './Loading.scss';

export const Loading: React.FC<LoadingTypes> = ({ name = '' }): JSX.Element => {
  return (
    <div className={`loadblock ${name}`}>
      <div className='loading'></div>
    </div>
  );
};

type LoadingTypes = {
  name?: string;
};

export function BtnLoading({ children }) {
  return <div className='btnloading'>{children}</div>;
}
