import React, { useState, useEffect } from 'react';
import './style/Input.scss';
import { ReactComponent as Eye } from '../../../img/icons/eye.svg';
import { ReactComponent as Close } from '../../../img/icons/closeEye.svg';
import InputErrFoot from './components/InputErrFoot';
import { change, focus, renderInput } from './components/inputFocus';
import { Location, useLocation } from 'react-router-dom';
import { valid } from './components/validate';
import { InputFiledTypes } from './Input.config';
import { UserLoginT, UserRegistrationT } from '../../../store';

export const InputPassword: React.FC<InputFiledTypes<any>> = ({
  type,
  textFiled,
  register,
  req,
  name,
  getValues,
  errors,
  twopass,
  setValue,
  title,
}) => {
  const [passSea, setPassSea] = useState(false);
  const { pathname }: Location = useLocation();
  const err: string = errors[name] && (errors[name]?.message || 'Error');

  useEffect(() => {
    renderInput();
  }, []);

  const сoincidence = getValues
    ? {
        pattern: !twopass ? valid(type) : undefined,
        validate: twopass
          ? (value: string) =>
              (getValues && value == getValues('password')) ||
              'The passwords do not match'
          : undefined,
      }
    : null;

  return (
    <div className='inputFiled'>
      <div className={title ? 'inputFiled__title' : ''}>
        <p className='big'>{title}</p>
      </div>
      <div className='inputFiled__block'>
        <div className='inputFiled__text old all'>
          <p className='mini'>{textFiled}</p>
        </div>
        <input
          {...register(name, {
            required: req ? 'Field must be filled' : false,
            ...сoincidence,
          })}
          onChange={(e) => change(e, setValue, name)}
          onFocus={focus}
          onBlur={focus}
          type={passSea ? 'text' : type}
        />
        <div
          onClick={() => setPassSea(() => !passSea)}
          className='inputFiled__eye'
        >
          {passSea ? <Close /> : <Eye />}
        </div>
      </div>
      <InputErrFoot err={err} />
    </div>
  );
};

export const Input: React.FC<InputFiledTypes<any>> = ({
  type,
  textFiled,
  register,
  req,
  name,
  errors,
  title,
  code,
  setValue,
}) => {
  const err: string = errors[name] && (errors[name]?.message || 'Error');

  useEffect(() => {
    renderInput();
  }, []);

  return (
    <div className='inputFiled'>
      {title && (
        <div className={title ? 'inputFiled__title' : ''}>
          <p className='big'>{title}</p>
        </div>
      )}
      <div className='inputFiled__block'>
        {code ? (
          <div className={`inputFiled__tel `}>
            <p className='mini'>{code}</p>
          </div>
        ) : (
          false
        )}
        <div
          className={
            code ? `inputFiled__text old all tel ` : `inputFiled__text old all`
          }
        >
          <p className='mini'>{textFiled}</p>
        </div>
        <input
          {...register(name, {
            required: req ? 'Field must be filled' : false,
            pattern: valid(type, name),
          })}
          onChange={(e) => change(e, setValue, name)}
          onFocus={focus}
          onBlur={focus}
          type={type}
        />
      </div>
      {title && <InputErrFoot err={err} />}
    </div>
  );
};
