import { Outlet } from 'react-router-dom';
import Modal from '../../Pages/Modal/Modal';
import { Footer, Navbar, FooterDeskont } from '../index';
import { useEffect } from 'react';
import {
  debounce,
  handlerSideBar,
  haveScrolled,
  onScroll,
} from './handlerScroll';

const Layout: React.FC = (): JSX.Element => {
  useEffect(() => {
    const scrolled = haveScrolled(handlerSideBar);
    const hendler = debounce(scrolled, 20);
    const scrolledAll = haveScrolled(onScroll);

    window.addEventListener('scroll', (e) => hendler(e));
    window.addEventListener('scroll', (e) => scrolledAll(e));
    return () => {
      window.removeEventListener('scroll', (e) => hendler(e));
      window.removeEventListener('scroll', (e) => scrolledAll(e));
    };
  }, []);

  return (
    <div className='wrapper '>
      <div className='positioncont scroll' data-footer='true'>
        <Modal />
        <Navbar />
        <Outlet />

        <FooterDeskont />
      </div>

      <Footer />
    </div>
  );
};
export default Layout;
