import './style/Paragraph.scss';

export default function Paragraph({ name, en, ru }) {
  return (
    <p className={`${name} lang`}>
      <span className='en'>{en}</span>
      <span className='ru'>{ru}</span>
    </p>
  );
}
