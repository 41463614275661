import { useAppDispatch, useAppSelector } from '../../../../store';
import { favoriteAdd } from '../../../../store/goodsSlices/favoriteSlice';
import { ReactComponent as Heart } from '../../../../img/icons/heart.svg';
import './HeartBtn.scss';

export const HeartBtn: React.FC<HeartT> = ({ model, id, one }) => {
  const { isAuth } = useAppSelector((state) => state.user);
  const { products } = useAppSelector((state) => state.favorite);
  const dispatch = useAppDispatch();

  const headerCheck = async () => {
    if (isAuth) {
      dispatch(favoriteAdd({ id }));
    } else {
      if (model) model('login', 'in');
    }
  };

  if (one) {
    return (
      <div onClick={headerCheck} className='oneproduct__heart'>
        <div
          className={
            products.filter((item) => item.id == id).length && isAuth
              ? 'heart add '
              : 'heart '
          }
        >
          <Heart />
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={headerCheck}
      className={
        products.filter((item) => item.id == id).length && isAuth
          ? 'heart add'
          : 'heart'
      }
    >
      <Heart />
    </div>
  );
};

type HeartT = {
  id: number;
  model?: (arg: string, arg2: string) => void;
  one?: boolean;
};
