import { useState } from 'react';

export default function useLoading(callback: (data: any) => void) {
  const [isLoadingFetch, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const fetching = async function (arg: any) {
    try {
      setIsLoading(true);
      callback(arg);
    } catch (err: any) {
      if (err instanceof Error) {
        setError(err.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return [isLoadingFetch, fetching, error] as const;
}
