import React from 'react';
import { publicRoutes } from './routes';
import { Routes, Route } from 'react-router-dom';

const Home = React.lazy(() => import('../Pages/Home/Home'));
const Layout = React.lazy(() => import('../components/Layouts/Layout'));

export const AppRouter: React.FC = (): JSX.Element => {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route element={<Home />} index />
        {publicRoutes.map(({ path, element }) => {
          return <Route element={element} path={path} key={path} />;
        })}
      </Route>
    </Routes>
  );
};
