import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  TypesConfig,
  actionDeviceAllT,
  initialStateHTTPT,
} from '../goods.config';
import { $host } from '../../http';
import axios, { AxiosError } from 'axios';
import { buidItogDevice } from './deviceHandler';
import { RootState } from '../../store';

export const productItogHTTP = createAsyncThunk<actionDeviceAllT, undefined>(
  'device/productSortHTTP',
  async function (_, { getState }) {
    return await buidItogDevice({ state: getState as () => RootState });
  }
);

export const initialStateHTTP = createAsyncThunk<initialStateHTTPT, undefined>(
  'device/initialStateHTTP',
  async function (_, { getState }) {
    try {
      const { data } = await $host.get('api/device/initial');

      const data2 = await buidItogDevice({
        state: getState as () => RootState,
        typeNew: data.type[0].id,
      });
      const newData = { ...data, ...data2 };

      return newData;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        throw data;
      }
    }
  }
);

export const getAllProductHTTP = createAsyncThunk<actionDeviceAllT, undefined>(
  'device/getAllProductHTTP',
  async function () {
    try {
      const { data } = await $host.get('api/device/');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        console.log(err.message);
      }
    }
  }
);

export const getBrandsHTTP = createAsyncThunk<TypesConfig[], undefined>(
  'device/getBrandsHTTP',
  async function () {
    try {
      const { data } = await $host.get('api/brand/');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        console.log(err.message);
      }
    }
  }
);

export const getColorAllHTTP = createAsyncThunk<TypesConfig[], undefined>(
  'device/getColorAllHTTP',
  async function () {
    try {
      const { data } = await $host.get('api/color/');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        console.log(err.message);
      }
    }
  }
);

export const getTypeHTTP = createAsyncThunk<TypesConfig[], undefined>(
  'device/getTypeHTTP',
  async function () {
    try {
      const { data } = await $host.get('api/category/');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        console.log(err.message);
      }
    }
  }
);
