import { $ } from '../../';

export function lodingDOM(theme) {
  const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');

  if (theme) {
    if (theme.value == 'dark') {
      $('.blockicons__theme, body').add('changeTheme');
    } else if (theme.value == 'light') {
      $('.blockicons__theme, body').remove('changeTheme');
    } else if (prefersDarkScheme) {
      $('.blockicons__theme, body').add('changeTheme');
    }
  }
}

export function changeMargin() {
  const height = getComputedStyle(
    document.querySelector('.footer') as HTMLElement
  ).getPropertyValue('height');

  (document.querySelector('#root') as HTMLElement).style.paddingBottom = height;
}
