import { useEffect } from 'react';

export default function Timer({ times, requestNumber, setTimes }: any) {
  useEffect(() => {
    let timeId: any;
    if (times > 0) {
      timeId = setTimeout(() => setTimes((times: number) => --times), 1000);
    }
    return () => clearTimeout(timeId);
  }, [times]);
  return (
    <div className="twofactor__repeat">
      {times ? (
        `You can resend in ${times} seconds`
      ) : (
        <p onClick={requestNumber}>Message resend</p>
      )}
    </div>
  );
}
