import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { $, Button } from 'components/index';
import { useAppDispatch } from '../../../store';
import { changeModal } from '../../../store/modalSlice/modalSlice';

import './style/Footer.scss';

export const FooterDeskont: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();

  function open() {
    dispatch(changeModal({ isActive: true, component: 'discont', subCom: '' }));
  }

  const path = useLocation().pathname;

  useEffect(() => {
    if (/admin|user|price|schedule|contacts/.test(path)) {
      $('.bannershop').add('none');
    } else {
      $('.bannershop').remove('none');
    }
  });

  return (
    <div className='bannershop scroll' data-sell>
      <div className='container'>
        <div className='bannershop__body'>
          <div className='bannershop__title'>
            <h1>
              get a discount on your
              <br /> first order
            </h1>
          </div>

          <div className='bannershop__btn'>
            <Button name='all' onClick={open}>
              <p className='mini'>get a discount</p>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
