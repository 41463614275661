import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice/userSlice';
import basketSlice from './goodsSlices/basketSlice';
import favoriteSlice from './goodsSlices/favoriteSlice';
import modalSlice from './modalSlice/modalSlice';
import deviceSlice from './goodsSlices/devicesAPI/deviceSlice';
import commentSlice from './commentSlice/commentSlice';
import ratingSlice from './goodsSlices/ratingSlice/ratingSlice';
import productSlice from './goodsSlices/productSlice/productSlice';
import deviceListSlice from './goodsSlices/deviceListSlice/deviceListSlice';

export const store = configureStore({
  reducer: {
    basket: basketSlice,
    favorite: favoriteSlice,
    user: userReducer,
    modal: modalSlice,
    device: deviceSlice,
    deviceSelect: deviceListSlice,
    comment: commentSlice,
    rating: ratingSlice,
    product: productSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
