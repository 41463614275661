import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ShopInitialT, TypesConfig } from '../goods.config';
import {
  addDeviceItog,
  caseItogDevice,
  changePageGoodsDevice,
} from './deviceHandler';
import {
  getAllProductHTTP,
  getBrandsHTTP,
  initialStateHTTP,
  productItogHTTP,
} from './deviceAPI';

const initialState: ShopInitialT = {
  goodsAll: [],
  goodsSort: [],
  goods: [],
  colors: [],
  brands: [],
  types: [],
  oneColor: [{ id: 0, name: 'ALL' }],
  oneBrand: [{ name: 'ALL', id: 0 }],
  oneType: {},
  filter: 'All',
  sum: 0,
  isLoading: true,
  page: '1',
  pages: '1',
  limit: '8',
};

const deviceSlice = createSlice({
  name: 'deficeSlice',
  initialState,
  reducers: {
    setDevice: addDeviceItog,
    changePage(state, action: PayloadAction<{ page: string }>) {
      state.page = action.payload.page;
      changePageGoodsDevice(state);
    },
    pageInitial(state) {
      const searchParams = new URLSearchParams(window.location.search);
      state.page = '1';
      searchParams.set('page', `1`);
      window.history.replaceState(
        {},
        '',
        `${location.pathname}?${searchParams}`
      );
    },
    setColors(state, action: PayloadAction<{ colors: TypesConfig[] }>) {
      state.oneColor = action.payload.colors;
    },
    setType(state, action: PayloadAction<{ type: TypesConfig }>) {
      state.oneType = action.payload.type;
    },
    setBrands(state, action: PayloadAction<{ brands: TypesConfig[] }>) {
      state.oneBrand = action.payload.brands;
    },
    changeLimitDevice(state, action: PayloadAction<{ limit: string }>) {
      state.limit = action.payload.limit;
      changePageGoodsDevice(state);
    },
    cgangeFilter(state, action: PayloadAction<{ filter: string }>) {
      state.filter = action.payload.filter;
    },
  },
  extraReducers: function (b) {
    b.addCase(productItogHTTP.pending, function (state) {
      state.isLoading = true;
    })
      .addCase(initialStateHTTP.pending, function (state) {
        state.isLoading = true;
      })
      .addCase(initialStateHTTP.fulfilled, function (state, action) {
        const params = new URLSearchParams(window.location.search);
        state.goods = [];

        if (action.payload.message === 'Ok') {
          const { type } = action.payload;

          state.goodsAll = action.payload.device;
          state.colors = action.payload.color;
          state.types = type;
          state.brands = action.payload.brand;

          if (!params.has('type')) {
            params.set('type', `${type[0].id}`);
            state.oneType = type[0];
          } else {
            state.oneType = type.filter((item) =>
              params.get('type')?.includes(item.id.toString())
            )[0];
          }

          if (!params.has('filter')) {
            params.set('filter', `low`);
          }

          window.history.replaceState({}, '', `${location.pathname}?${params}`);

          state.page = params.get('page')?.toString() || '1';

          caseItogDevice(state, action);
        }
      })
      .addCase(productItogHTTP.fulfilled, function (state, action) {
        const { types, colors, brands } = state;
        const params = new URLSearchParams(window.location.search);
        caseItogDevice(state, action);

        if (params.has('type')) {
          const typeParams = params.get('type') as string;

          state.oneType = types.filter((item) => +item.id === +typeParams)[0];
        } else {
          params.set('type', `${types[0].id}`);
          state.oneType = types[0];
          window.history.replaceState({}, '', `${location.pathname}?${params}`);
        }

        state.page = params.get('page')?.toString() || '1';

        const brand: TypesConfig[] = [];
        const color: TypesConfig[] = [];

        for (const [key, value] of params.entries()) {
          if (key.startsWith('brand')) {
            brand.push(brands.filter((item) => +item.id === +value)[0]);
          } else if (key.startsWith('color')) {
            color.push(colors.filter((item) => +item.id === +value)[0]);
          }
        }

        state.oneBrand = brand;
        state.oneColor = color;
      })
      .addCase(getAllProductHTTP.fulfilled, function (state, action) {
        state.goodsAll = action.payload.rows;
      })
      .addCase(getBrandsHTTP.fulfilled, function (state, action) {
        state.brands = action.payload;
      })
      .addMatcher(
        ({ type }) => /^device\/.*?\/fulfilled$/.test(type),
        loadingend
      );
  },
});

function loadingend(state) {
  state.isLoading = false;
}

export const {
  changePage,
  setDevice,
  changeLimitDevice,
  pageInitial,
  setBrands,
  setColors,
  setType,
} = deviceSlice.actions;
export default deviceSlice.reducer;
