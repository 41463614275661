import { useState, useEffect, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TwoFactor } from '../TwoFactor/TwoFactor';
import Body from './components/UpBody/Body';
import {
  UserRegistrationT,
  coderUserHTTP,
  UpUserPreHTTP,
  useAppDispatch,
  useAppSelector,
  changePageUser,
  clearCodeUser,
  errorChange,
} from '../../../../store';
import { changeModal } from '../../../../store/modalSlice/modalSlice';
import { $, validateUserValue } from '../../../../components';

import './style/SignUp.scss';
import { ContextPortal } from '../../../../components/UI/Portal/PortalCont';
import { SignInUpConf } from '../SignIn/SignIn';

const SignUp: React.FC<SignInUpConf> = ({ close }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
    setValue,
    setError,
  } = useForm<UserRegistrationT>({ mode: 'onBlur' });
  const [value, setValues] = useState<string>('');
  const dispatch = useAppDispatch();
  const { toggleAdd } = useContext(ContextPortal);
  const { code, page, isLoading, error } = useAppSelector(
    (store) => store.user
  );

  const firstFetch = async (body: UserRegistrationT) => {
    await dispatch(UpUserPreHTTP({ body }));
  };

  const fetching = async () => {
    if (code === value) {
      await dispatch(coderUserHTTP());
      close();
      dispatch(changePageUser({ to: '' }));
      toggleAdd({
        en: 'You are logged in',
        ru: 'Вы успешно авторизовались',
        time: 2000,
      });
    } else {
      $('.numberCode input').add('err');
    }
  };

  const onSubmit: SubmitHandler<UserRegistrationT> = (data) => {
    if (page.includes('first')) {
      dispatch(changePageUser({ to: 'second' }));
    } else if (page.includes('second')) {
      firstFetch(data);
    } else if (page.includes('code')) {
      fetching();
    }
  };

  function model(value: string) {
    dispatch(changePageUser({ to: value }));
  }

  useEffect(() => {
    if (!isLoading && 'message' in error) {
      validateUserValue({ data: error, setError, model });
      setTimeout(() => dispatch(errorChange()));
    }
  }, [error]);

  return (
    <form className='signup' onSubmit={handleSubmit(onSubmit)}>
      {!page.includes('code') ? (
        <Body
          setValue={setValue}
          register={register}
          errors={errors}
          getValues={getValues}
          loading={isLoading}
        />
      ) : (
        <TwoFactor to='second' setValue={setValues} />
      )}
    </form>
  );
};

export default SignUp;
