import { ReactComponent as Arrow } from 'img/icons/arrow.svg';

export default function PriceItem({ item, ...props }) {
  return (
    <>
      <h2 className='priceList__title'>{item.date}</h2>
      <div {...props} className='priceList__header'>
        <p className='priceList__subtitle big'>{item.subtitle}</p>
        <p className='arrow'>
          <Arrow />
        </p>
      </div>
      <div className='priceList__body'>
        <div className='priceList__text'>
          <p className='priceList__svg'>&bull;</p>
          <p>{item.dateall}</p>
        </div>
        {item.elemets.map((el) => {
          return (
            <div key={Math.random()} className='priceList__text'>
              <p className='priceList__svg'>&bull;</p>
              <p>{el}</p>
            </div>
          );
        })}
        <div className='priceList__footer'>
          <p className='big'>
            {item.price}
            <span>/mo</span>
          </p>
          <p className='mini'>Price per month</p>
        </div>
      </div>
    </>
  );
}
