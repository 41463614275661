import {
  BtnLoading,
  Button,
  Input,
  Loading,
} from '../../../../../../components';
import { SignInputAll } from '../../../../../../interface/ui.interface';
import { UserRegistrationT } from '../../../../../../store';

const FirstPageUp: React.FC<SignInputAll<UserRegistrationT>> = ({
  setValue,
  register,
  errors,
  loading,
}) => {
  return (
    <>
      <div className='upbody__body'>
        <div className='upbody__name'>
          <Input
            req={true}
            register={register}
            title='First Name'
            type='text'
            name='firstName'
            textFiled='FirstName'
            errors={errors}
            setValue={setValue}
          />

          <Input
            req={true}
            register={register}
            title='Last Name'
            type='text'
            name='lastName'
            textFiled='LastName'
            errors={errors}
            setValue={setValue}
          />
        </div>
        <Input
          req={true}
          register={register}
          title='Login'
          type='text'
          name='login'
          textFiled='Login'
          errors={errors}
          setValue={setValue}
        />

        <Input
          req={true}
          register={register}
          title='Email'
          type='email'
          name='email'
          textFiled='Email'
          errors={errors}
          setValue={setValue}
        />
      </div>
      <div className='upbody__footer'>
        <div></div>
        <div className='upbody__btn'>
          {loading ? (
            <BtnLoading>
              <Loading />
            </BtnLoading>
          ) : (
            <Button name='sign'>
              <p className='mini'>Next</p>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default FirstPageUp;
