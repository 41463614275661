import { UseFormSetError } from 'react-hook-form';

export type ValidT = {
  data: any;
  setError: UseFormSetError<any>;
  login?: boolean;
  model?: (value: string) => void;
};

export const validateUserValue = ({
  data,
  setError,
  login = false,
  model,
}: ValidT) => {
  if (!login) {
    if (/email/gi.test(data.message)) {
      setError('email', {
        type: 'custom',
        message: data.message,
      });
      if (model) model('second');
    } else if (/number/gi.test(data.message)) {
      setError('number', {
        type: 'custom',
        message: data.message,
      });
    } else if (/login/gi.test(data.message)) {
      setError('login', {
        type: 'custom',
        message: data.message,
      });
      if (model) model('second');
    } else {
      alert(data.message);
    }
  } else if (login) {
    if (/email/gi.test(data.message)) {
      setError('email', {
        type: 'custom',
        message: data.message,
      });
    } else if (/password/gi.test(data.message)) {
      setError('password', {
        type: 'custom',
        message: data.message,
      });
    } else {
      alert(data.message);
    }
  }
};
