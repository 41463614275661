import React, { useEffect } from 'react';
import { ReactComponent as Sun } from '../../../../../img/icons/sun.svg';
import { ReactComponent as Moon } from '../../../../../img/icons/moon.svg';

import Sign from './components/Sign';
import { ActiveUser } from './components/ActiveUser';
import { lodingDOM } from '../../../../UX/loadWin/loadWin';
import { useAppSelector } from '../../../../../store';

import './style/BlockIcons.scss';

export const BlockIcons = (): JSX.Element => {
  const { isAuth } = useAppSelector((state) => state.user);

  const changeTheme = (e: React.MouseEvent<HTMLElement>) => {
    const target = (
      (e.target as HTMLElement).closest('.blocktheme') as HTMLElement
    ).dataset.theme as string;
    let color = 'false';

    if (target) {
      if (target == 'dark') {
        color = 'light';
      } else {
        color = 'dark';
      }
    }

    const date = new Date();

    const theme = {
      value: color,
      timestamp: date.setDate(date.getDate() + 1),
    };
    localStorage.setItem('theme', JSON.stringify(theme));

    lodingDOM(theme);
  };

  useEffect(() => {
    let theme = JSON.parse(localStorage.getItem('theme') || 'false');

    if (theme && new Date(theme.timestamp) < new Date()) {
      theme = localStorage.setItem('theme', 'false');
    }
    lodingDOM(theme);
  });

  return (
    <div className={isAuth ? 'blockicons active' : 'blockicons '}>
      <div className='blockicons__items'>
        <div className='blockicons__elements'>
          <div onClick={changeTheme} className='blockicons__theme'>
            <div>
              <div data-theme='sun' className='blockicons__sun blocktheme'>
                <Sun />
              </div>
              <div data-theme='dark' className='blockicons__moon blocktheme'>
                <Moon />
              </div>
            </div>
          </div>

          {isAuth ? <ActiveUser /> : <Sign />}
        </div>
      </div>
    </div>
  );
};
