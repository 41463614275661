import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ProductInitialT, ProductT } from '../goods.config';
import { $host } from '../../http';
import axios, { AxiosError } from 'axios';

const initialState: ProductInitialT = {
  product: {},
  isLoading: true,
};

type GetProductT = {
  device: ProductT;
  message: string;
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {},
  extraReducers: function (b) {
    b.addCase(getProduct.pending, function (state) {
      state.product = {};
      state.isLoading = true;
    }).addCase(
      getProduct.fulfilled,
      function (state, action: PayloadAction<GetProductT>) {
        if (action.payload.message === 'Ok') {
          state.product = action.payload.device;
          state.isLoading = false;
        }
      }
    );
  },
});

export const getProduct = createAsyncThunk<
  GetProductT,
  { id: string | number }
>('product/getProduct', async function ({ id }) {
  try {
    const { data } = await $host.get('api/device/' + id);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      return data;
    }
  }
});

export default productSlice.reducer;
