import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { $host } from '../http';
import { ComeBasketFavoriteT, FavoriteInitialT } from './goods.config';
import axios, { AxiosError } from 'axios';

const initialState: FavoriteInitialT = {
  products: [],
  productsLimit: [],
  sale: 0,
  loading: true,
};

const favoriteSlice = createSlice({
  name: 'favorite',
  initialState,
  reducers: {},
  extraReducers: function (b) {
    b.addCase(favoriteAdd.fulfilled, addConf)
      .addCase(favoriteAll.fulfilled, addConf)
      .addCase(favoriteLimit.pending, function (state) {
        state.loading = true;
      })
      .addCase(
        favoriteLimit.fulfilled,
        function (state, action: PayloadAction<ComeBasketFavoriteT>) {
          state.productsLimit = action.payload.devices;
          state.loading = false;
        }
      )
      .addCase(favoriteClear.fulfilled, addConf);
  },
});

function addConf(state, action: PayloadAction<ComeBasketFavoriteT>) {
  if (action.payload.devices) {
    state.products = action.payload.devices;
  }
  state.loading = false;
}

export const favoriteLimit = createAsyncThunk<
  ComeBasketFavoriteT,
  { limit: string }
>('basket/favoriteLimit', async function ({ limit }) {
  try {
    const { data } = await $host.get('api/favorite/', {
      params: {
        _limit: limit,
      },
    });
    return data;
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      return data;
    }
  }
});

export const favoriteAll = createAsyncThunk<ComeBasketFavoriteT>(
  'basket/favoriteAll',
  async function () {
    try {
      const { data } = await $host.get('api/favorite/');
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        return data;
      }
    }
  }
);

export const favoriteAdd = createAsyncThunk<
  ComeBasketFavoriteT,
  { id: number }
>('basket/favoriteAdd', async function ({ id }) {
  try {
    const { data } = await $host.put(`api/favorite/product/${id}/append`);
    return data;
  } catch (error) {
    const err = error as AxiosError;
    if (axios.isAxiosError(err) && err.response) {
      const {
        response: { data },
      } = err;
      return data;
    }
  }
});

export const favoriteClear = createAsyncThunk<ComeBasketFavoriteT, undefined>(
  'basket/favoriteClear',
  async function () {
    try {
      const { data } = await $host.put(`api/favorite/clear`);
      return data;
    } catch (error) {
      const err = error as AxiosError;
      if (axios.isAxiosError(err) && err.response) {
        const {
          response: { data },
        } = err;
        return data;
      }
    }
  }
);

export default favoriteSlice.reducer;
