import React from 'react';
import { ReactComponent as X } from '../../../../img/icons/x.svg';
import SignIn from '../SignIn/SignIn';
import SignUp from '../SignUp/SignUp';
import './style/Login.scss';
import {
  clearCodeUser,
  useAppDispatch,
  useAppSelector,
} from '../../../../store';
import {
  closeModel,
  closeThenModel,
} from '../../../../store/modalSlice/modalSlice';
import { $ } from '../../../../components';

export const Login: React.FC = (): JSX.Element => {
  const { subCom } = useAppSelector((state) => state.modal);
  const dispatch = useAppDispatch();

  function close() {
    dispatch(closeModel());
    dispatch(clearCodeUser());
    $('.modal').elem.ontransitionend = function () {
      dispatch(closeThenModel());
      $('.modal').elem.ontransitionend = function () {
        return false;
      };
    };
  }

  const Components = {
    in: SignIn,
    up: SignUp,
  };

  const Component = subCom !== undefined && Components[subCom];

  return (
    <div className='login'>
      <div className='login__close'>
        <div onClick={close} className='login__x'>
          <X />
        </div>
      </div>
      {Component !== undefined && <Component close={close} />}
    </div>
  );
};
