import {
  BtnLoading,
  Button,
  Input,
  InputPassword,
  Loading,
} from '../../../../../../components';
import { ISecondPageUp } from '../../../../../../interface/ui.interface';
import { changePageUser, useAppDispatch } from '../../../../../../store';

const SecondPageUp: React.FC<ISecondPageUp> = ({
  setValue,
  register,
  errors,
  getValues,
  loading,
}) => {
  const dispatch = useAppDispatch();
  const back = () => {
    dispatch(changePageUser({ to: 'first' }));
  };
  return (
    <>
      <div className='upbody__body'>
        <InputPassword
          req={true}
          title='Password'
          name='password'
          register={register}
          type='password'
          textFiled='Your password'
          setValue={setValue}
          errors={errors}
          getValues={getValues}
        />
        <InputPassword
          req={true}
          name='passTwo'
          title='Password repeat'
          register={register}
          type='password'
          textFiled='Repeat password'
          setValue={setValue}
          errors={errors}
          getValues={getValues}
          twopass={true}
        />
        <Input
          setValue={setValue}
          req={true}
          title='Number'
          register={register}
          type='tel'
          name='number'
          textFiled='Number'
          errors={errors}
          code='+1'
        />
      </div>
      <div className='upbody__footer'>
        <p onClick={back} className='mini twofactor__back'>
          {'< Back'}
        </p>
        <div className='upbody__btn'>
          {loading ? (
            <BtnLoading>
              <Loading />
            </BtnLoading>
          ) : (
            <Button name='sign'>
              <p className='mini'>Next</p>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default SecondPageUp;
