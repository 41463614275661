import React, { useEffect, useState } from 'react';
import { NumberValue } from '../../../../../../interface/ui.interface';
import './style/NumberCode.scss';
import ChangeFiled from './СhangeFiled';
import BackSpace from './BackSpace';

export default function NumberCode({ setValue }: any) {
  const [code, setCode] = useState<NumberValue[]>([
    { name: '1', val: '' },
    { name: '2', val: '' },
    { name: '3', val: '' },
    { name: '4', val: '' },
  ]);

  useEffect(() => {
    setValue(
      `${code
        .map((item) => {
          return item.val;
        })
        .join('')}`
    );
  }, [code]);

  return (
    <div className='numberCode'>
      {code.map((item) => {
        return (
          <input
            key={item.name}
            type='text'
            name={item.name}
            onChange={(e) => new ChangeFiled(setCode, e, code).validate()}
            value={item.val}
            onKeyDown={(e) => new BackSpace(setCode, e, code).back()}
          />
        );
      })}
    </div>
  );
}
