import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { addClassBurger, showMenu } from 'jsFunction/index';
import { $, Paragraph } from 'components/index';
import { links } from 'Router';

import './style/NavBlock.scss';

export const NavBlock: React.FC = () => {
  const arr = [
    { name: 'Home', nameru: 'Главная', path: links.HOME_ROUTER },
    { name: 'Shop', nameru: 'Магазин', path: links.SHOP_ROUTER },
    { name: 'Price', nameru: 'Цены', path: links.PRICE },
    { name: 'Сontacts', nameru: 'Контакты', path: links.GYM },
    { name: 'Schedule', nameru: 'График', path: links.SCHEDULE },
  ];
  const local = useLocation();
  const link: RegExpMatchArray | null =
    local.pathname.match(/\/[a-z0-9%&$-]*/gi);

  const goRoof = (e: React.MouseEvent) => {
    document.documentElement.scrollIntoView(true);
    showMenu();
    addClassBurger(e);
  };

  useEffect(() => {
    setTimeout(function timer() {
      const element = document.querySelectorAll('.load');
      if (element.length > 0) {
        $('.header__line').add('loader');
        $('.header__linesecond').add('loaded');
        $('.header__linesecond').style('transform', `translateX(${0}px)`);
        $('.header__linesecond').style('width', `30%`);
        setTimeout(timer, 1000);
      } else {
        $('.header__line').remove('loader');
        $('.header__linesecond').remove('loaded');
        chagneCourseLine();
      }
    });
  });

  useEffect(() => {
    window.addEventListener('resize', chagneCourseLine);
    window.addEventListener('scroll', chagneCourseLine);
    return () => {
      window.removeEventListener('resize', chagneCourseLine);
      window.removeEventListener('scroll', chagneCourseLine);
    };
  }, []);

  useEffect(() => {
    chagneCourseLine();
  });

  const mouse = (e) => {
    const li = e.target.closest('li');
    $(li).siblings().remove('had');
    li?.classList.add('had');

    const line = document.querySelector('.header__linesecond') as HTMLElement;
    const course = li.querySelector('p').getBoundingClientRect().left;
    const width = getComputedStyle(li.querySelector('p')).getPropertyValue(
      'width'
    );

    if (!line.closest('.header__line.loader')) {
      line.style.width = width;
      line.style.transform = `translateX(${course}px)`;
    }
  };

  return (
    <nav className='header__links'>
      <ul onMouseOut={chagneCourseLine} className='header__link'>
        {arr.map((item) => {
          return (
            <li
              onMouseOver={mouse}
              key={item.name}
              onClick={(e) => goRoof(e)}
              className={link && link[0] == item.path ? 'check had' : ''}
            >
              <Link to={item.path}>
                <Paragraph name='link' ru={item.nameru} en={item.name} />
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

function chagneCourseLine() {
  const li = document.querySelector('li.check');
  const line = document.querySelector('.header__linesecond') as HTMLElement;

  $('.header__links li').remove('had');
  li?.classList.add('had');

  if (!line.closest('.header__line.loader')) {
    if (document.documentElement.clientWidth > 1004) {
      line.style.display = 'block';
      if (li) {
        const width = getComputedStyle(
          document.querySelector('.header__link li.check p') as HTMLElement
        ).getPropertyValue('width');

        const course = (
          document.querySelector('.header__link li.check p') as HTMLElement
        ).getBoundingClientRect().left;

        line.style.width = width;
        line.style.transform = `translateX(${course}px)`;
      } else {
        const width = getComputedStyle(
          document.querySelector('.header__link li p') as HTMLElement
        ).getPropertyValue('width');
        const course = (
          document.querySelector('.header__link li p') as HTMLElement
        ).getBoundingClientRect().left;

        line.style.width = width;
        line.style.transform = `translateX(${course}px)`;
      }
    } else line.style.display = 'none';
  }
}
