import React, { useEffect } from 'react';
import { Logo } from './conmopents/Logo/Logo';
import { NavBlock } from './conmopents/NavBlock/NavBlock';
import { BlockIcons } from './conmopents/BlockIcons/BlockIcons';
import { HeaderMenu } from './conmopents/HeaderMenu/HeaderMenu';
import { ReactComponent as Phone } from '../../../img/icons/phone.svg';
import { ReactComponent as Calen } from '../../../img/icons/calendar.svg';
import Paragraph from '../../UI/Paragraph/Paragraph';
import { useLocation } from 'react-router-dom';
import { addClassBurger, showMenu } from '../../../jsFunction/index';
import { $ } from '../../';

import { useAppDispatch } from '../../../store';
import { changeModal } from '../../../store/modalSlice/modalSlice';

import './style/Header.scss';
import './style/BreakPoints.scss';

export function Navbar() {
  const dispatch = useAppDispatch();

  function open() {
    dispatch(changeModal({ isActive: true, component: 'free', subCom: '' }));
  }

  const clickBurger = (e) => {
    (document.querySelector('.menu__body') as HTMLElement)?.scrollIntoView({
      block: 'start',
      behavior: 'auto',
    });
    $('.goroof').remove('show');
    addClassBurger(e);
  };

  const router = useLocation();

  useEffect(() => {
    showMenu();
  }, []);

  return (
    <>
      <div className='phone lock-padding'>
        <div className='container'>
          <div className='phone__contain'>
            <a href='tel:+19992059331' className='phone__body'>
              <div className='phone__icon'>
                <Phone />
              </div>
              <div className='phone__text'>
                <Paragraph name='mini' ru='Позвонить' en='Contact us' />
              </div>
            </a>
            <div className='enroll'>
              <div onClick={open} className='enroll__body'>
                <div className='enroll__icons'>
                  <Calen />
                </div>
                <div className='enroll__text'>
                  <Paragraph
                    name='mini'
                    ru='Записаться'
                    en='Enroll first training'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header
        className={
          router.pathname == '/'
            ? 'header home lock-padding'
            : 'header lock-padding'
        }
      >
        <HeaderMenu />
        <div className='header__raw'>
          <div className='container'>
            <div className='header__wrap'>
              <div onClick={clickBurger} className='header__burger'>
                <span></span>
              </div>
              <Logo />
              <NavBlock />
              <BlockIcons />
            </div>
          </div>
          <div className='header__line loader'>
            <div className='header__linesecond'></div>
          </div>
        </div>
      </header>
    </>
  );
}
