export const pricearr = [
  {
    id: 1,
    date: 'Started',
    price: '$60.25',
    subtitle: 'One month',
    dateall: '30 days Expiration',
    elemets: [
      `Lorem ipsum dolor sit amet`,
      `Lorem ipsum dolor sit amet`,
      `Lorem ipsum dolor sit amet`,
      `Lorem ipsum dolor sit`,
    ],
  },
  {
    id: 2,
    date: 'Crowth',
    price: '$55.99',
    subtitle: 'Half-year',
    dateall: '6 Month Expiration',
    elemets: [
      `Lorem ipsum dolor sit amet consectetur`,
      ` Lorem ipsum dolor sit amet consectetur, adipisicing elit.
	  Temporibus, odio.`,
      `Lorem ipsum dolor sit amet consectetur`,
    ],
  },
  {
    id: 3,
    date: 'Golden',
    price: '$50',
    subtitle: 'Year',
    dateall: '12 Month Expiration',
    elemets: [
      `Lorem ipsum dolor sit amet consectetur`,
      ` Lorem ipsum dolor sit amet consectetur, adipisicing`,
      `Temporibus, odio.`,
      `Lorem ipsum dolor sit amet consectetur`,
    ],
  },
];
