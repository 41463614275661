import DocumentMeta from 'react-document-meta';

export const MetaData: React.FC<MetaDataConf> = ({
  title,
  keywords,
  disc,
  children,
}) => {
  const meta = {
    title,
    description: disc || 'Fight Gym home page. Information about club',
    meta: {
      charset: 'utf-8',
      name: {
        keywords:
          keywords || 'fyghtGym, club, boxing, fitness, sporting goods store',
      },
    },
  };

  return <DocumentMeta {...meta}>{children}</DocumentMeta>;
};

interface MetaDataConf {
  title: string;
  keywords?: string;
  disc?: string;
  children: JSX.Element | JSX.Element[];
}
